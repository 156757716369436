import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { Container } from "@mui/material";
import { useQueries } from "@tanstack/react-query";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import ErroCarregamentoModal from "../../components/errors/ErrorModal";
import FormCurso from "../../components/formularios/FormCurso";
import LoadingComponent from "../../components/loading/LoadingComponent";
import TitlePage from "../../components/title-page/TitlePage";
import parceiroService from "../../services/parceiro.service";

const PageVisualizarCurso = () => {
  const { id } = useParams();

  const results = useQueries({
    queries: [
      {
        queryKey: ["curso", id],
        queryFn: () => {
          return parceiroService.getCurso(id);
        },
      },
      { queryKey: ["empresasParceiras"], queryFn: parceiroService.getEmpresasParceiras },
      { queryKey: ["trilhasCurso"], queryFn: parceiroService.getTrilhasCursos },
    ],
  });

  const [cursoQuery, empresasParceirasQuery, trilhasQuery] = results;

  const curso = cursoQuery.data;
  const listaEmpresas = empresasParceirasQuery.data;
  const listaTrilhas = trilhasQuery.data;

  const isPending = cursoQuery.isLoading || empresasParceirasQuery.isLoading || trilhasQuery.isLoading;
  const isError = cursoQuery.isError || empresasParceirasQuery.isError || trilhasQuery.isError;

  useEffect(() => {
    if (curso && listaEmpresas) {
      const ofertante = listaEmpresas.find(e => e.pk_empresa_parceira === curso.cnpjOfertante);
      curso.nomeOfertante = ofertante ? ofertante.no_fantasia : "";
    }
  }, [curso, listaEmpresas]);

  // console.log("curso -> ", curso);

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[{ href: "/cursos", text: "Cursos" }]}
        currentPage={"Visualizar"}
      />

      <TitlePage title={"Visualizar Dados do Curso"} />

      {isPending ? (
        <LoadingComponent
          isLoading={isPending}
          fullScreen={false}
        />
      ) : isError ? (
        <ErroCarregamentoModal isShow={true} />
      ) : (
        <FormCurso
          initialValues={curso}
          listaEmpresas={listaEmpresas}
          listaTrilhas={listaTrilhas}
          // listaPreRequisitos={["matematica", "tecnologia da informação", 3, 4]}
          visualizacao={true}
        />
      )}
    </Container>
  );
};

export default PageVisualizarCurso;
