const parceiroNavConfig = [
  {
    title: "Página Inicial",
    path: "/",
    color: "default",
    subTitles: {
      home: "/home",
      perfil: "/profile",
    },
  },
  {
    title: "parceiro",
    path: "/parceiro",
    subTitles: {
      "Nova Inscrição de Parceiro": "/empresa/cadastro",
      "Listar Parceiros": "/empresa/listar",
    },
  },
  {
    title: "Cursos",
    path: "/cursos",
    subTitles: {
      "Novo Curso (HU023)": "/cursos/cadastrar",
      "Editar Curso (HU033)": "/cursos/editar",
      "Duplicar Curso (HU037)": "/cursos/duplicar",
      "Listar Cursos (HU024, HU035)": "/cursos/listar",
      "Visualizar Curso (HU034)": "/cursos/visualizar",
      "Relação de Interessados (HU036)": "/cursos/interessados",
    },
  },
  {
    title: "Vagas",
    path: "/vagas",
    subTitles: {
      "Cadastrar Vaga": "/vagas/cadastrar",
      "Listar Vagas": "/vagas/listar",
      "Buscar Currículos": "/vagas/curriculos/busca",
    },
  },
  {
    title: "Mobilização",
    path: "/mobilizacao",
    subTitles: {
      "Encontre Currículos em sua Região": "/mobilizacao/curriculos/buscar",
      "Visualizar Currículo Mobilização (HU060)": "/mobilizacao/curriculos/visualizar",
      //"Interesses da Pessoa (HU111)": "/mobilizacao/interesses-da-pessoa",
      "Mobilização Individual do Cidadão (HU113)": "/mobilizacao/mobilizacao-individual-cidadao",
      //"Curriculo do Cidadão (HU114)": "/mobilizacao/curriculo-cidadao",
      //"Editar Currículo do Cidadão (HU115)": "/mobilizacao/editar-curriculo-cidadao",
      "Listar Vagas na Região do Cidadão (HU122)": "/mobilizacao/vagas/listar",
      "Visualizar Informações da Vaga (HU123)": "/mobilizacao/vagas/visualizar",
      "Mobilização Buscar Cursos (HU052)": "/mobilizacao/cursos/buscar",
      "Mobilização Buscar Vagas (HU053)": "/mobilizacao/vagas/buscar",
      "Listar Cursos na Região do Cidadão (HU124)": "/mobilizacao/cursos/listar",
      "Buscar operador de microcrédito (HU144)": "/mobilizacao/listar-mpo/consultar",
      "Novo curso Empreender (HU162)": "/mobilizacao/cursos/novo",
    },
  },
  {
    title: "Microcrédito",
    path: "microcredito",
    subTitles: {
      //"Quero Ser Empreendedor (HU151)": "/microcredito/quero-ser-empreendedor",
      //"Já Sou Empreendedor (HU152)": "/microcredito/ja-sou-empreendedor",
      //"Mobilização Individual - Acesso a Microcrédito Produto Orientado (HU147)": "/microcredito/acesso-mpo",
      //"Mobilização Individual - Acesso a Microcrédito Produto Orientado Aprovado (HU149)": "/microcredito/acesso-mpo-aprovado",
      //"Microcrédito MPO (HU148)": "/microcredito/credito-mpo",
      // "Questionário Microcrédito MPO (HU148)": "/microcredito/credito-mpo/questionario",
      //"Informar Situação Contato (HU150)": "/microcredito/informar-situacao-contato",
      "Perguntas Frequentes Microcrédito (HU153)": "/microcredito/FAQ",
    },
  },
  {
    title: "[DEV] Components",
    path: "/components",
    color: "default",
    subTitles: {
      Components: "/components",
      "Default Table": "/components-default-table",
      "Dynamic Form": "/components-form",
    },
  },
  {
    title: "Menu Temporário",
    path: "/",
    color: "default",
    subTitles: {
      "Nova Inscrição de Parceiro (HU001)": "/empresa/cadastro",
      "Visualizar Parceiro (não tem HU)": "/empresa/visualizar",
      "Listar Parceiros  (HU016, HU145)": "/empresa/listar",
      "Excluir Parceiro (HU017)": "/empresa/excluir",
      "Editar Parceiro (HU018)": "/empresa/editar",
      "Usuário do Parceiro (HU019)": "/empresa/representantes",
      "Novo Curso (HU023)": "/cursos/cadastrar",
      "Editar Curso (HU033)": "/cursos/editar",
      "Visualizar Curso (HU034)": "/cursos/visualizar",
      "Informações Complementares (HU126)": "/empresa/cadastrar",
      "Visualizar Informações Complementares (HU130)": "/empresa/visualizar",
      "Listar Cursos (HU024, HU035)": "/cursos/listar",
      "Relação de Interessados (HU036)": "/cursos/interessados",
      "Duplicar Curso (HU037)": "/cursos/duplicar",
      "Cadastrar Vaga de Trabalho (HU054)": "/vagas/cadastrar",
      "Editar Vaga de Trabalho (HU056)": "/vagas/editar",
      "Duplicar Vaga de Trabalho (HU062)": "/vagas/duplicar",
      "Listar Vagas de Trabalho (HU055), (HU057), (HU061), (HU066)": "/vagas/listar",
      "Listar Pessoas Interessadas (HU059)": "/vagas/interessados",
      "Buscar Currículos (HU058)": "/vagas/curriculos/busca",
      "Buscar Currículos Mobilização (HU110)": "/mobilizacao/curriculos/buscar",
      "Visualizar Currículo (HU060)": "/vagas/curriculos/visualizar",
      "Visualizar Currículo Mobilização (HU060)": "/mobilizacao/curriculos/visualizar",
      //"Interesses da Pessoa (HU111)": "/mobilizacao/interesses-da-pessoa",
      "Mobilização Individual do Cidadão (HU113)": "/mobilizacao/mobilizacao-individual-cidadao",
      //"Curriculo do Cidadão (HU114)": "/mobilizacao/curriculo-cidadao",
      //"Editar Currículo do Cidadão (HU115)": "/mobilizacao/editar-curriculo-cidadao",
      "Listar Vagas na Região do Cidadão (HU122)": "/mobilizacao/vagas/listar",
      "Visualizar Informações da Vaga (HU123)": "/mobilizacao/vagas/visualizar",
      "Mobilização Buscar Cursos (HU052)": "/mobilizacao/cursos/buscar",
      "Mobilização Buscar Vagas (HU053)": "/mobilizacao/vagas/buscar",
      "Listar Cursos na Região do Cidadão (HU124)": "/mobilizacao/cursos/listar",
      "Visualizar Informações Curso (HU125)": "/mobilizacao/cursos/visualizar",
      //"Quero Ser Empreendedor (HU151)": "/microcredito/quero-ser-empreendedor",
      //"Já Sou Empreendedor (HU152)": "/microcredito/ja-sou-empreendedor",
      "Listar Interessados em MPO (HU143)": "/listar-mpo",
    },
  },
];

export default parceiroNavConfig;
