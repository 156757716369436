import { calculaVigencia, transformaCurso, transformaDetalhes } from "../utils/parceiroCursoUtils";
import transformDadosEmpresaComplementar from "../utils/parceiroEmpresaComplementarUtils";
import { transformarDadosUnicaEmpresa, transformEmpresasComDetalhes } from "../utils/parceiroEmpresaUtils";
import { transformaDadosVaga, transformaDadosVagaSimples } from "../utils/parceiroVagaUtils";
import axiosInstance from "./axiosConfig";
import API_URL from "./config";
import constantsService from "./constants.service";
/**
 * Recupera um parceiro usando o token de acesso fornecido e o ID fornecido.
 *
 * @param {number} id - O ID do parceiro a ser recuperado
 * @return {Promise} Uma Promessa que resolve para os dados do parceiro recuperado
 */
const getParceiroData = () => {
  return axiosInstance
    .get(API_URL + "v1/parceiros/index/", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      // console.error("Error fetching parceiro data:", error);
      throw error;
    });
};

/**
 * Cadastra uma nova empresa parceira.
 *
 * @param {Object} data - Os dados da empresa parceira a serem cadastrados.
 * @return {Promise<string>} Uma promessa que resolve para a mensagem de sucesso da operação.
 * @throws {Error} Se ocorrer algum erro durante o cadastro da empresa parceira.
 */
const postCadastrarEmpresa = data => {
  return axiosInstance
    .post(API_URL + `v1/parceiros/empresa/cadastro`, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      // console.log("Service parceiro");
      return response.data.empresa_id;
    })
    .catch(error => {
      console.error("Error cadastrando empresa parceira:", error);
      throw error;
    });
};

/**
 * Recupera uma lista de empresas parceiras.
 *
 * @return {Promise<Array>} Uma promessa que resolve para um array de empresas parceiras.
 */
const getEmpresasParceiras = () => {
  return axiosInstance
    .get(API_URL + `v1/parceiros/empresas`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      // TODO: checar quando não tem nenhuma empresa
      if (!response.data || response.data.message === "Nenhum curso encontrado.") {
        console.log("Nenhuma empresa encontrada");
        return [];
      }

      return response.data.map(empresa => {
        let situacao = constantsService.getStatusByCode(empresa.administracao_registros.tp_situacao_registro || "");
        if (situacao === "Pendente ADM") {
          situacao = "Pendente";
        }

        return { ...empresa, situacao, status: situacao };
      });
    })
    .catch(error => {
      console.error("Error fetching empresas parceiras:", error);
      throw error;
    });
};

/**
 * Recupera os detalhes de uma empresa parceira usando o ID fornecido.
 *
 * @param {number} id - O ID da empresa parceira a ser recuperada.
 * @return {Promise<Object>} Uma Promessa que resolve para os detalhes da empresa parceira recuperada.
 * @throws {Error} Se ocorrer algum erro durante a recuperação dos detalhes da empresa parceira.
 */
const getEmpresaData = id => {
  return axiosInstance
    .get(API_URL + `v1/parceiros/empresa/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      return transformarDadosUnicaEmpresa(response.data);
    })
    .catch(error => {
      console.error("Error fetching empresa parceira details:", error);
      throw error;
    });
};

/**
 * Recupera uma lista de empresas parceiras com seus detalhes.
 *
 * @return {Promise<Array>} Uma promessa que resolve para um array de empresas parceiras com seus detalhes.
 */
const getEmpresasParceirasComDetalhes = async () => {
  try {
    const response = await getEmpresasParceiras();

    // Mapear cada empresa para buscar detalhes individuais
    const empresas = Object.values(response);

    // Map each empresa to fetch individual details
    const detalhesPromises = empresas.map(empresa => {
      return getEmpresaData(empresa.pk_empresa_parceira);
    });

    // Esperar por todas as chamadas de detalhes
    const detalhes = await Promise.all(detalhesPromises);

    // Combinar dados brutos com detalhes
    const empresasComDetalhes = empresas.map((empresa, index) => ({
      ...empresa,
      detalhes: detalhes[index],
    }));

    return transformEmpresasComDetalhes(empresasComDetalhes);
  } catch (error) {
    console.error("Error fetching empresas parceiras:", error);
    throw error;
  }
};

/**
 * Exclui uma empresa parceira usando o ID fornecido.
 *
 * @param {number} id - O ID da empresa parceira a ser excluída.
 * @param {string} motivo - O motivo da exclusão da empresa parceira.
 * @return {Promise<string>} Uma Promessa que resolve para a mensagem de sucesso da operação.
 * @throws {Error} Se ocorrer algum erro durante a exclusão da empresa parceira.
 */
const excluiEmpresaParceira = (id, motivo) => {
  return axiosInstance
    .delete(API_URL + `v1/parceiros/empresa/exclui/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: {
        motivo: motivo,
      },
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.error("Error deleting empresa parceira:", error);
      throw error;
    });
};

/**
 * Atualiza os dados de uma empresa parceira usando o ID fornecido.
 *
 * @param {number} id - O ID da empresa parceira a ser atualizada.
 * @param {Object} empresaData - Os novos dados da empresa parceira.
 * @return {Promise<string>} Uma Promessa que resolve para a mensagem de sucesso da operação.
 * @throws {Error} Se ocorrer algum erro durante a atualização dos dados da empresa parceira.
 */
const atualizaEmpresaParceira = (id, empresaData) => {
  return axiosInstance
    .put(API_URL + `v1/parceiros/empresa/atualiza/${id}`, empresaData, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    })
    .then(response => {
      console.log("Empresa atualizada com sucesso");
      return response.data;
    })
    .catch(error => {
      console.error("Error updating empresa parceira:", error);
      throw error;
    });
};

/**
 * Cadastra uma empresa complementar.
 *
 * @param {object} data - Os dados a serem enviados no corpo da requisição.
 * @returns {Promise<string>} Uma promessa que resolve com o cadastro da empresa complementar.
 * @throws {Error} Se ocorrer algum erro durante o cadastro da empresa complementar.
 */
const postCadastrarEmpresaComplementar = data => {
  return axiosInstance
    .post(API_URL + `v1/parceiros/empresa_complementar/cadastro`, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      console.log("Empresa complementar cadastrada com sucesso");
      return response.data;
    })
    .catch(error => {
      console.error("Error cadastrando empresa complementar parceira:", error);
      throw error;
    });
};

/**
 * Recupera os detalhes de uma empresa complementar usando o ID fornecido.
 *
 * @param {number} id - O ID da empresa complementar a ser recuperada.
 * @return {Promise<Object>} Uma Promessa que resolve para os detalhes da empresa complementar recuperada.
 * @throws {Error} Se ocorrer algum erro durante a recuperação dos detalhes da empresa complementar.
 */
const getEmpresaComplementar = id => {
  return axiosInstance
    .get(API_URL + `v1/parceiros/empresa_complementar/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      const dados = transformDadosEmpresaComplementar(response.data);
      return dados;
    })
    .catch(error => {
      console.error("Error fetching empresa complementar parceira details:", error);
      throw error;
    });
};

/**
 * Atualiza os dados de uma empresa complementar usando o ID fornecido.
 *
 * @param {number} id - O ID da empresa complementar a ser atualizada.
 * @param {Object} empresaData - Os novos dados da empresa complementar.
 * @return {Promise<string>} Uma Promessa que resolve para a mensagem de sucesso da operação.
 * @throws {Error} Se ocorrer algum erro durante a atualização dos dados da empresa complementar.
 */
const atualizaEmpresaComplementar = (id, data) => {
  return axiosInstance
    .put(API_URL + `v1/parceiros/empresa_complementar/atualiza/${id}`, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    })
    .then(response => {
      console.log("Empresa complementar atualizada com sucesso");
      return response.data;
    })
    .catch(error => {
      console.error("Erro ao tentar atualizar empresa complementar", error);
      throw error;
    });
};

/**
 * Cadastra um anexo.
 *
 * @param {Array} arquivos - Os arquivos a serem enviados no corpo da requisição.
 * @return {Promise<string>} Uma promessa que resolve para a mensagem de sucesso da operação.
 * @throws {Error} Se ocorrer algum erro durante a inserção dos anexos.
 */
const postInsereArquivo = file => {
  try {
    return axiosInstance
      .post(API_URL + `v1/parceiros/anexo/insere_foto_curso/`, file, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(response => {
        // console.log("Inseri arquivo", response.data);
        // Preciso pegar o response.data.id para colocar no id do curso
        return response.data;
      })
      .catch(error => {
        console.error("Error Post Insere Arquivo:", error);
        throw error;
      });
  } catch (error) {
    const erro = "Erro Post Insere Arquivo:" + error;
    throw erro;
  }
};

/**
 * Cadastra uma novo curso.
 *
 * @param {Object} data - Os dados do curso a serem cadastrados.
 * @return {Promise<string>} Uma promessa que resolve para a mensagem de sucesso da operação.
 * @throws {Error} Se ocorrer algum erro durante o cadastro do curso.
 */
const postCadastrarCurso = data => {
  return axiosInstance
    .post(API_URL + `v1/parceiros/curso/cadastro`, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      console.log("Service parceiro", response.data);
      return response.data.message;
    })
    .catch(error => {
      console.error("Error cadastrando curso:", error);
      throw error;
    });
};

/**
 * Obter a lista de cursos com detalhes das empresas parceiras.
 *
 * @return {Promise<string>} Uma promessa que resolve para os dados dos cursos cadastrados.
 * @throws {Error} Se ocorrer algum erro durante a obtenção dos cursos.
 */
const getCursosComDetalhes = async () => {
  // Cache to store fetched company data by company ID
  const empresaCache = {};

  try {
    const response = await axiosInstance.get(API_URL + `v1/parceiros/cursos`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    // console.log("Service parceiro", response.data);

    if (!response.data || response.data.message === "Nenhum curso encontrado.") {
      console.log("Nenhum curso encontrado");
      return [];
    }

    const info = await Promise.all(
      response.data.map(async curso => {
        const empresaId = curso.dados.fk_empresa_parceira;

        // Check if the company data is already cached
        let dadosEmpresa = empresaCache[empresaId];
        if (!dadosEmpresa) {
          // Fetch and cache the company data if not already cached
          dadosEmpresa = await getEmpresaData(empresaId);
          empresaCache[empresaId] = dadosEmpresa;
        }

        const id = curso.curso_id;
        const status = constantsService.getStatusByCode(curso.tp_situacao_registro);
        let dadosCurso = transformaCurso(curso.dados);
        const vigencia = calculaVigencia(curso.tp_situacao_registro, curso.dados.dt_inicio_matricula, curso.dados.dt_fim_matricula);

        dadosCurso = {
          ...dadosCurso,
          cnpj: dadosEmpresa.cnpj,
          nomeOfertante: dadosEmpresa.nomeFantasia,
        };

        return { id, status, situacao: status, ...dadosCurso, vigencia };
      }),
    );

    return info;
  } catch (error) {
    console.error("Erro obtendo cursos:", error);
    return [];
  }
};

/**
 * Obter a lista de cursos.
 *
 * @return {Promise<string>} Uma promessa que resolve para os dados dos cursos cadastrados.
 * @throws {Error} Se ocorrer algum erro durante a obtenção dos cursos.
 */
const getCursos = async () => {
  try {
    const response = await axiosInstance.get(API_URL + `v1/parceiros/cursos`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    if (!response.data || response.data.message === "Nenhum curso encontrado.") {
      console.log("Nenhum curso encontrado");
      return [];
    }

    const info = response.data.map(curso => {
      const id = curso.curso_id;
      const status = constantsService.getStatusByCode(curso.tp_situacao_registro);
      let dadosCurso = transformaCurso(curso.dados);
      const vigencia = calculaVigencia(curso.tp_situacao_registro, curso.dados.dt_inicio_matricula, curso.dados.dt_fim_matricula);

      return { id, status, situacao: status, ...dadosCurso, vigencia };
    });

    return info;
  } catch (error) {
    console.error("Erro obtendo cursos:", error);
    return [];
  }
};

/**
 * Obter os dados de um curso.
 *
 * @param {Int} od - O id do curso a ser retornado.
 * @return {Promise<string>} Uma promessa que resolve para os dados do curso
 * @throws {Error} Se ocorrer algum erro durante a obtenção do curso.
 */
const getCurso = id => {
  return axiosInstance
    .get(API_URL + `v1/parceiros/curso/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(async response => {
      const id = response.data.curso_id;
      const status = constantsService.getStatusByCode(response.data.tp_situacao_registro);
      const dadosCurso = transformaCurso(response.data.dados);
      const dadosDetalhes = response.data.detalhes ? transformaDetalhes(response.data.detalhes) : null;
      const vigencia = calculaVigencia(
        response.data.tp_situacao_registro,
        response.data.dados.dt_inicio_matricula,
        response.data.dados.dt_fim_matricula,
      );

      return { id, status, situacao: status, ...dadosCurso, detalhes: dadosDetalhes, vigencia };
    })
    .catch(error => {
      console.error("Erro obtendo curso:", error);
      throw error;
    });
};

/**
 * Obter os dados das trilhas de cursos.
 *
 * @return {Promise<string>} Uma promessa que resolve para os dados das trilhas.
 * @throws {Error} Se ocorrer algum erro durante a obtenção das trilhas.
 */
const getTrilhasCursos = () => {
  return axiosInstance
    .get(API_URL + `v1/parceiros/trilha_curso`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.error("Error obtendo trilhas:", error);
      throw error;
    });
};

/**
 * Cadastra um novo representante de uma empresa  usando dados fornecidos.
 *
 * @param {Object} data - Os dados do representante a ser cadastrado.
 * @return {Promise<string>} Uma promessa que resolve para a mensagem de sucesso da operação.
 * @throws {Error} Se ocorrer algum erro durante o cadastro do representante.
 */
const postRepresentanteEmpresa = data => {
  return axiosInstance
    .post(API_URL + `v1/parceiros/representantes/cadastro`, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    })
    .then(response => {
      console.log(response.data.message);
    })
    .catch(error => {
      console.error("Erro ao cadastrar representante: ", error);
      throw error;
    });
};

/**
 * Obtem a lista de representantes de uma empresa parceira usando o ID fornecido.
 *
 * @param {number} id - O ID da empresa parceira cujos representantes serão recuperados.
 * @return {Promise<Array>} Uma promessa que resolve para um array de representantes da empresa parceira.
 * @throws {Error} Se ocorrer algum erro durante a recuperação dos representantes da empresa.
 */
const getRepresentantesEmpresa = id => {
  if (id) {
    return axiosInstance
      .get(API_URL + `v1/parceiros/representantes/${id}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(response => {
        // Retorna os dados dos representantes da empresa
        return response.data;
      })
      .catch(error => {
        // Loga o erro e lança uma exceção em caso de falha na requisição
        console.error("Erro ao obter os representantes da empresa: ", error);
        throw error;
      });
  } else {
    // Retorna um array vazio se o ID não for fornecido
    return [];
  }
};

/**
 * Exclui um representante de uma empresa parceira.
 *
 * @param {number} id - O ID do representante a ser excluído.
 * @param {string} motivo - O motivo da exclusão do representante.
 * @return {Promise<string>} Uma promessa que resolve para a mensagem de sucesso da exclusão.
 * @throws {Error} Se ocorrer algum erro durante a exclusão do representante.
 */
const excluiRepresentanteEmpresa = (id, motivo) => {
  return axiosInstance
    .delete(API_URL + `v1/parceiros/representantes/exclui/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: motivo,
    })
    .then(response => {
      return response.data.message;
    })
    .catch(error => {
      console.error("Erro ao tentar excluir representante: ", error);
      throw error;
    });
};

/**
 * Cadastra uma vaga
 * @param {object} data - Os dados a serem enviados no corpo da requisição.
 * @returns {Promise<string>} Uma promessa que resolve com o cadastro da vaga.
 * @throws {Error} Se ocorrer algum erro durante o cadastro da vaga.
 */
const postCadastrarVaga = data => {
  return axiosInstance
    .post(API_URL + `v1/parceiros/vaga/cadastro`, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      console.log("Service parceiro", response.data);
      return response.data.message;
    })
    .catch(error => {
      console.error("Error cadastrando vaga:", error);
      throw error;
    });
};

/**
 * Recupera os detalhes de uma lista de vagas usando o ID fornecido.
 *
 * @return {Promise<Object>} Uma Promessa que resolve para as vagas cadastradas.
 * @throws {Error} Se ocorrer algum erro durante a recuperação das vagas.
 */
const getVagas = () => {
  //TODO: Pedir pro Lucas não separar por empresa
  return axiosInstance
    .get(API_URL + `v1/parceiros/vagas?id_empresa_parceira=3`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      // console.log("Pegando vagas", response.data);
      const info = response.data.map(vaga => {
        return transformaDadosVagaSimples(vaga);
      });
      return info;
    })
    .catch(error => {
      console.error("Error fetching vagas:", error);
      throw error;
    });
};

/**
 * Recupera os detalhes de uma vaga usando o ID fornecido.
 * @param {number} id - O id da vaga.
 * @param {number} id_empresa_parceira - O id da empresa parceira.
 * @return {Promise<Object>} Uma Promessa que resolve para a vaga cadastrada.
 * @throws {Error} Se ocorrer algum erro durante a recuperação da vaga.
 */
const getVaga = async (id, id_empresa_parceira) => {
  try {
    const response = await axiosInstance.get(API_URL + `v1/parceiros/vaga/${id}?id_empresa_parceira=${id_empresa_parceira}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    if (!response.data || response.data.message === "Nenhuma vaga encontrada!") {
      console.log("Nenhuma vaga encontrada!");
      return null;
    }

    const dadosEmpresa = await getEmpresaData(id_empresa_parceira);
    // console.log("Service parceiro", response.data);

    let dadosVaga = transformaDadosVaga(response.data);
    dadosVaga = {
      ...dadosVaga,
      cnpjOfertante: dadosEmpresa.cnpj,
      nomeOfertante: dadosEmpresa.nomeFantasia,
    };
    // console.log("Retornando vaga", dadosVaga);
    return dadosVaga;
  } catch (error) {
    console.error("Erro ao obter vaga:", error);
    throw error;
  }
};

const getOcupacoesCB2002 = () => {
  return axiosInstance
    .get(API_URL + `v1/trabalhador/cbo2002`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      // TODO: Remover limitação de 10
      const ocupacoes = response.data.slice(0, 10).map(ocupacao => ({
        id: ocupacao.id_cbo2002.toString(),
        descricao: ocupacao.nome_ocupacao,
      }));

      return ocupacoes;
    })
    .catch(error => {
      console.error("Error fetching vagas:", error);
      throw error;
    });
};

const parceiroService = {
  getParceiroData,
  getEmpresaData,
  postCadastrarEmpresa,
  getEmpresasParceiras,
  excluiEmpresaParceira,
  atualizaEmpresaParceira,
  getEmpresasParceirasComDetalhes,
  postCadastrarEmpresaComplementar,
  getEmpresaComplementar,
  atualizaEmpresaComplementar,
  postInsereArquivo,
  postCadastrarCurso,
  getCursos,
  getCursosComDetalhes,
  getCurso,
  getTrilhasCursos,
  postRepresentanteEmpresa,
  getRepresentantesEmpresa,
  excluiRepresentanteEmpresa,
  postCadastrarVaga,
  getVagas,
  getVaga,
  getOcupacoesCB2002,
};

export default parceiroService;
