const adminNavConfig = [
  {
    title: "Página Inicial",
    path: "/admin",
    color: "default",
    subTitles: {
      home: "/",
      perfil: "/profile",
    },
  },
  {
    title: "Parceiros",
    path: "/parceiros",
    subTitles: {
      "Listar Parceiros Pendentes": "/parceiros/pendentes",
      "Listar Parceiros Aprovados": "/parceiros/aprovados",
      "Listar Parceiros Reprovados": "/parceiros/reprovados",
      "Listar Representantes do Parceiro": "/parceiros/representantes",
    },
  },
  {
    title: "Cursos",
    path: "/cursos",
    subTitles: {
      "Listar Cursos Pendentes": "/cursos/pendentes",
      "Listar Cursos Aprovados": "/cursos/aprovados",
      "Listar Cursos Reprovados": "/cursos/reprovados",
      "Listar Cursos com Site Inativo": "/cursos/site-inativo",
      "Listar Trilhas": "/trilhas/",
    },
  },
  {
    title: "Microcrédito",
    path: "microcredito",
    subTitles: {
      "Já Sou Empreendedor (HU133)": "/microcredito/ja-sou-empreendedor",
      "Quero Ser Empreendedor (HU134)": "/microcredito/quero-ser-empreendedor",
    },
  },
  {
    title: "Trilhas",
    path: "trilhas",
    subTitles: {
      Listar: "/trilhas",
      "Nova trilha": "/trilhas/novo/",
    },
  },
  {
    title: "[DEV] Components",
    path: "/components",
    color: "default",
    subTitles: {
      Components: "/components",
      "Default Table": "/components-default-table",
      "Dynamic Form": "/components-form",
    },
  },
  {
    title: "Menu Temporário",
    path: "/",
    color: "default",
    subTitles: {
      "Listar Parceiros Pendentes (HU002)": "/parceiros/pendentes",
      "Analisar Parceiro Pendente (HU003, HU005, HU006, HU007)": "/parceiros/analisar",
      "Visualizar Parceiro Pendente (HU004)": "/parceiros/visualizar",
      //"Cadastrar Informações Complementares (HU127)": "/parceiros/empresa-complementar/cadastrar",
      "Analisar Informações Complementares (HU128)": "/parceiros/empresa-complementar/analisar",
      "Visualizar Informações Complementares (HU129)": "/parceiros/empresa-complementar/visualizar",
      "Listar pessoas interessadas (HU008)": "/vagas/interessadas/",
      "Currículo (HU009)": "/vagas/interessadas/curriculo/",
      "Listar Parceiros Aprovados (HU010)": "/parceiros/aprovados",
      "Listar Parceiros Reprovados (HU011)": "/parceiros/reprovados",
      "Listar interessados no curso (HU012)": "/cursos/interessadas/",
      "Visualizar curso (HU013)": "/cursos/visualizar",
      "Visualizar Alterações (HU014)": "/parceiros/alteracoes/visualizar",
      "Visualizar Alterações Documentos (HU015)": "/parceiros/alteracoes-documentos/visualizar",
      "Listar Representantes do Parceiro (HU020)": "/parceiros/representantes",
      "Inativar Parceiro (HU021)": "/parceiros/reprovados/inativar",
      //"Editar Parceiro (HU022)": "/parceiros/editar",
      "Listar Cursos Pendentes (HU025, HU028)": "/cursos/pendentes",
      "Analisar Curso (HU026)": "/cursos/pendentes/analisar/",
      "Vizualizar Alterações Curso (HU027)": "/cursos/pendentes/analisar/alteracoes",
      "Listar Cursos Aprovados (HU029, HU030)": "/cursos/aprovados",
      "Listar Cursos Reprovados (HU031, HU032)": "/cursos/reprovados",
      "Listar Cursos com Site Inativo (HU039)": "/cursos/site-inativo",
      "Histórico Alterações Curso (HU040)": "/cursos/pendentes/analisar/historico",
      "Histórico Cursos (HU041)": "/cursos/pendentes/historico",
      "Nova Trilha (HU042)": "/trilhas/novo",
      "Listar Trilhas (HU043, HU045, HU046, HU047)": "/trilhas",
      "Editar Trilha (HU044)": "/trilhas/editar",
      "Visualizar Trilha (HU048)": "/trilhas/visualizar",
      "Incluir Curso (HU049, HU050)": "/trilhas/incluir",
      "Listar Anexos Curso (HU051)": "/cursos/anexos",
      "Listar Vagas (HU063, HU065)": "/vagas/listar",
      "Visualizar Vagas (HU064)": "/vagas/visualizar",
      "Critérios Vaga (HU067)": "/vagas/visualizar/criterios",
      "Listar usuários (HU073, HU075, HU076)": "/usuarios",
      "Novo Usuário (HU072)": "/usuarios/novo/",
      "Editar Usuário (HU074)": "/usuarios/editar/",
      "Excluir Usuário (HU077)": "/usuarios/excluir/",
      "Visualizar Usuário (HU078)": "/usuarios/visualizar",
      "Histórico Situação Usuário (HU079)": "/usuarios/visualizar/historico",
      "Listar Perfis (HU081, HU083, HU084)": "/perfis/listar/",
      "Novo Perfil (HU080)": "/perfis/novo/",
      "Editar Perfil (HU082)": "/perfis/editar/",
      "Excluir Perfil (HU085)": "/perfis/excluir/",
      "Visualizar Perfil (HU086)": "/perfis/visualizar/",
      "Histórico Situação Perfil (HU087)": "/perfis/visualizar/historico/",
      "Listar Interessados MPO (HU131, HU135)": "/microcredito/listar",
      "Situação Interessado MPO (HU132)": "/microcredito/situacao",
      "Listar Interessados MPO por Parceiro (HU141)": "/microcredito/interessados-parceiro",
      "Consultar parceiro operador de microcrédito (HU142)": "/microcredito/consultar",
      "Consultar painel de indicadores (HU158)": "/consultar-painel-indicadores",
      "Gerar relatorio de indicadores (HU159)": "/gerar-relatorio-indicadores",
      "Vizualizar modelos de E-mail (HU154 - HU157, HU161)": "/emails",
      "Já Sou Empreendedor (HU133)": "/microcredito/ja-sou-empreendedor",
      "Quero Ser Empreendedor (HU134)": "/microcredito/quero-ser-empreendedor",
      "Alerta Admin sobre Pedido de Microcrédito (HU160)": "/microcredito/pedidos-microcredito",
      "Consultar Jornada do Cidadão (HU163)": "/microcredito/consultar-jornada-cidadao",
    },
  },
];

export default adminNavConfig;
