import React from "react";

import { Container } from "@mui/material";
import { useQuery } from "@tanstack/react-query";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import ErroCarregamentoModal from "../../components/errors/ErrorModal";
import FormQueroSerEmpreendedor from "../../components/formularios/FormQueroSerEmpreendedor";
import LoadingComponent from "../../components/loading/LoadingComponent";
import TitlePage from "../../components/title-page/TitlePage";
import cidadaoService from "../../services/cidadao.service";

const PageQuestionarioQueroSerEmpreendedor = () => {
  const linksBreadCrumb = [
    { href: "/", text: "Cidadão" },
    { href: "/empreendedorismo", text: "Empreendedorismo" },
    { href: "/empreendedorismo", text: "Microcrédito" },
    { href: "/empreendedorismo", text: "Acessar microcrédito" },
  ];

  // Conexão com o back-end
  const {
    isPending,
    isError,
    data: initialData,
  } = useQuery({
    queryKey: ["formularioQueroEmpreender", 1],
    queryFn: ({ queryKey }) => {
      const [, id] = queryKey;
      return cidadaoService.getQueroEmpreender(id);
    },
  });

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        currentPage={"Questionário - Quero ser empreendedor"}
        links={linksBreadCrumb}
      />

      <TitlePage title={"Questionário Quero Ser Empreendedor"} />

      <div style={{ fontFamily: "Rawline Regular" }}>
        <p>
          {
            "Ao responder o questionário abaixo, o cidadão autoriza o Ministério do Desenvolvimento e Assistência Social, Família e Combate à Fome a enviar os seus dados e informações aos operadores de microcrédito parceiros do Acredita, visando permitir a prospecção direta desses parceiros sobre a sua pessoa, por qualquer meio, buscando dar efetividade à política de microcrédito produtivo orientado e de inclusão social e produtiva, bem como a realizar consultas para verificação da existência de restrições junto a instituições que controlam cadastros positivos e restritivos de crédito."
          }
        </p>
        <p>
          {
            "Os operadores de microcrédito parceiros do Acredita podem ser bancos, cooperativas de crédito, agências de fomento, sociedades de crédito ao  microempreendedor e à empresa de pequeno porte, organizações da sociedade civil de interesse público, pessoas jurídicas especializadas no apoio, no fomento ou na orientação de microcrédito produtivo orientado, instituição financeira que realiza  operações exclusivamente por meio de sítio eletrônico ou de aplicativo"
          }
        </p>
      </div>

      <div>
        {isPending ? (
          <LoadingComponent
            isLoading={isPending}
            fullScreen={false}
          />
        ) : isError ? (
          <ErroCarregamentoModal isShow={true} />
        ) : (
          <FormQueroSerEmpreendedor
            initialValues={initialData}
            visualizacao={!!initialData}
          />
        )}
      </div>
    </Container>
  );
};

export default PageQuestionarioQueroSerEmpreendedor;
