import React from "react";

import { Container } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import AdminListarHistoricoCurso from "../../components/filtros/AdminListarHistoricoCurso";
import TitlePage from "../../components/title-page/TitlePage";

const PageHistoricoCursos = () => {
  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[{ href: "/cursos/pendentes", text: "Listar Cursos Pendentes" }]}
        currentPage="Histórico do curso"
      />

      <TitlePage title={"Histórico do curso"} />

      <AdminListarHistoricoCurso />
    </Container>
  );
};

export default PageHistoricoCursos;
