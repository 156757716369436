import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Container } from "@mui/material";
import { useQuery } from "@tanstack/react-query";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import ErroCarregamentoModal from "../../components/errors/ErrorModal";
import AdminTrilha from "../../components/formularios/AdminTrilha";
import LoadingComponent from "../../components/loading/LoadingComponent";
import TitlePage from "../../components/title-page/TitlePage";
import adminService from "../../services/admin.service";
import constantsService from "../../services/constants.service";

const PageEditarTrilha = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  // Conexão com o back-end
  const {
    isPending,
    isError,
    data: initialData,
    error,
  } = useQuery({
    queryKey: ["trilhaCursoEditar", id],
    queryFn: ({ queryKey }) => {
      const [, id] = queryKey;
      return adminService.getTrilhaCurso(id);
    },
    enabled: !!id,
  });

  const atualizarTrilhaEStatus = async (trilhaPayload, values, id) => {
    try {
      const respostaTrilha = await adminService.atualizarTrilhaCurso(trilhaPayload, id);
      console.log("Sucesso ao inserir trilha: ", respostaTrilha);

      if (values.situacao !== "none") {
        const statusPayload = {
          trilha_id: id,
          status: constantsService.getCodeByStatus(values.situacao),
          mensagem: values.descricao,
        };

        const respostaStatus = await adminService.postAtualizarStatusTrilha(statusPayload);
        console.log("Sucesso ao atualizar o status da trilha: ", respostaStatus);
      }

      navigate("/trilhas/listar");
    } catch (erro) {
      console.error("Erro ao atualizar trilha ou status da trilha", erro);
    }
  };

  const handleSubmit = async values => {
    const files = values.anexo;

    if (!files?.length) {
      console.log("Insira um arquivo");
      return;
    }

    const arquivo = {
      nome_anexo: files[0].name.split(".")[0],
      extensao_anexo: files[0].name.split(".")[1],
      file: files[0].file,
    };

    // console.log(arquivo);

    try {
      let anexoId;
      const arquivoCompleto = `${arquivo.nome_anexo}.${arquivo.extensao_anexo}`;

      if (arquivoCompleto === initialData.anexo[0].name) {
        anexoId = initialData.anexo[0].id;
      } else {
        const respostaArquivo = await adminService.postInsereArquivoTrilha(arquivo);
        console.log("Sucesso ao inserir arquivo:", respostaArquivo.message);
        anexoId = respostaArquivo.id;
      }

      const trilhaPayload = {
        nome_trilha: values.nome,
        descricao_trilha: values.descricao,
        anexo: anexoId,
      };

      await atualizarTrilhaEStatus(trilhaPayload, values, id);
    } catch (erro) {
      console.error("Erro ao processar trilha", erro);
    }
  };

  if (isError) {
    console.log("Erro de carregamento:", error);
  }

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[
          { href: "/trilhas", text: "Trilhas" },
          { href: "/trilhas", text: "Listar" },
        ]}
        currentPage="Editar"
      />

      <TitlePage title={"Editar"} />

      {isPending ? (
        <LoadingComponent
          isLoading={isPending}
          fullScreen={false}
        />
      ) : (
        <AdminTrilha
          initialValues={initialData}
          onSubmit={handleSubmit}
          editar={true}
        />
      )}

      {isError && <ErroCarregamentoModal isShow={true} />}
    </Container>
  );
};

export default PageEditarTrilha;
