import React from "react";

import { FormGroup } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ptBR } from "@mui/x-date-pickers/locales";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import PropTypes from "prop-types";

import "dayjs/locale/pt-br";

const CustomDatePicker = ({ label, id, name, value, onChange, disabled, error, helperText }) => {
  return (
    <FormGroup>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale="pt-br"
        localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}
      >
        <DatePicker
          label={label}
          id={id}
          name={name}
          value={value ? dayjs(value) : null}
          format="DD/MM/YYYY"
          onChange={onChange}
          dayOfWeekFormatter={weekday => `${weekday.format("ddd")}`}
          disabled={disabled}
          slotProps={{
            textField: {
              error: error,
              helperText: helperText,
              color: error ? "error" : "primary",
            },
          }}
        />
      </LocalizationProvider>
    </FormGroup>
  );
};

CustomDatePicker.propTypes = {
  initialValues: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};

export default CustomDatePicker;
