// import { formatCPF, formatTelefone } from "../components/formularios/utils";

export function payloadJaSouEmpreendedor(formData) {
  return {
    perguntas: {
      // Qual a atividade do seu negócio?
      co_cnae_subclasse: formData.atividadeVariacaoNegocio,
      // Você tem interesse em variar a linha de atuação do seu negócio?
      st_pergunta_01: formData.interesseVariarAtuacao ? "SIM" : "NAO",
      // Qual(is) a(s) fonte(s) de recursos para manutenção do seu negócio?
      ds_pergunta_03: encodeBooleansToBitsFonteRecurso(formData),
      // Você tem controle de entradas e saídas de dinheiro do seu negócio?
      st_pergunta_03: formData.controleEntradas ? "SIM" : "NAO",
      // Você separa as compras do seu negócio das que você faz para sua família?
      st_pergunta_04: formData.separaCompras ? "SIM" : "NAO",
      // Como você faz a compra de produtos e serviços para seu negócio?
      ds_pergunta_04: encodeBooleansToBitsCompraProdutos(formData),
      // Você já precisou de financiamento/empréstimo para melhorar seu negócio?
      st_pergunta_06: formData.precisouFinanciamento ? "SIM" : "NAO",
      // Você calcula o custo fixo e variável por unidade do produto/serviço prestado?
      st_pergunta_07: formData.calculaCustoFixo ? "SIM" : "NAO",
      // Você faz algo para reduzir custos/despesas?
      st_pergunta_08: formData.fazAlgoReduzirCusto ? "SIM" : "NAO",
      // Você sabe dizer quanto você produz por mês?
      tp_produz_mes: formData.radioLucro,
      // Há quanto tempo você tem esse negócio?
      nu_tempo_negocio: formData.tempoNegocio,
      // Como você vê seu negócio daqui a 3 anos?
      st_pergunta_05: formData.comoVeNegocio3Anos,
      // Como você faz a propaganda do seu negócio?
      ds_pergunta_05: encodeBooleansToBitsMarketing(formData),
      // Por que o consumidor deveria comprar seu produto ou serviço?
      ds_motivo_consumir: formData.porqueConsumidorDeveriaComprar,
      // O que seu negócio precisa para crescer?
      ds_pergunta_11: encodeBooleansToBitsNegocioCrescer(formData),
      // Qual a situação do seu negócio?
      ds_pergunta_12: formData.radioSituacao,
      // CNPJ
      nu_cnpj: formData.cnpj,
      // Você gostaria de receber orientação sobre como administrar seu negócio?
      st_pergunta_09: formData.gostariaReceberOrientacao ? "SIM" : "NAO",
      // Já pensou em desistir do negócio?
      st_pergunta_10: formData.jaPensouDesistir ? "SIM" : "NAO",
      // Por que você tem o seu próprio negócio?
      ds_pergunta_15: encodeBooleansToBitsMotivoNegocio(formData),
      // Você tem algum funcionário trabalhando no seu negócio?
      st_pergunta_11: formData.temAlgumFuncionario ? "SIM" : "NAO",
      // Você já participou de algum curso sobre empreendedorismo, microcrédito, educação financeira?
      st_pergunta_12: formData.participouAlgumCurso ? "SIM" : "NAO",
      // Você gostaria de participar de algum curso sobre empreendedorismo, microcrédito,
      // educação financeira, de outros assuntos para melhor gerir o seu negócio?
      st_pergunta_13: formData.gostariaParticiparCurso ? "SIM" : "NAO",
      // Motivo para participar do curso
      ds_motivo_pergunta_18: formData.motivoParticiparCurso,
      // Você gostaria de receber orientação sobre como administrar seu negócio?
      st_pergunta_14: formData.gostariaReceberOrientacaoAdministracao,
      // Motivo para receber orientação
      ds_motivo_pergunta_19: formData.motivoReceberOrientacao,
      // Você tem necessidade de um financiamento/empréstimo de microcrédito?
      st_pergunta_15: formData.necessidadeFinanciamento ? "SIM" : "NAO",
      // Motivo para receber financiamento
      ds_motivo_pergunta_20: formData.motivoNecessidadeFinanciamento,
      // Você conhece os empréstimos para grupos de microempreendedores que utilizam o chamado aval
      // solidário como garantia de pagamento do empréstimo?
      st_pergunta_16: formData.conheceEmprestimos ? "SIM" : "NAO",
      // Em que local funciona o seu negócio?
      ds_pergunta_22: formData.radioLocalNegocio,
      // Em que local funciona o seu negócio? (outros)
      ds_pergunta_22_outro: formData.radioLocalNegocio == "outros" ? formData.radioLocalNegocio : "",
      no_completo: formData.nomeCompleto,
      dt_nascimento: formData.dataNascimento,
      nu_rg: formData.rg,
      nu_cpf: formData.cpf.replace(/\D/g, ""),
      tp_genero: formData.generoRadio,
      tp_escolaridade: formData.escolaridade,
      endereco: {
        ...formData.enderecoCompleto[0],
        logradouro: formData.enderecoCompleto[0].endereco,
        cidade: formData.enderecoCompleto[0].municipio,
        telefone: formData.telefone,
      },
      ds_email: formData.email,
    },
    cursos: formData.jaParticipouCursoDetalhes.map(curso => ({
      no_instituicao: curso.value1,
      no_curso: curso.value2,
    })),
  };
}

// Baseada na encodeBooleansToBits
function encodeBooleansToBitsFonteRecurso(data) {
  const booleans = [data.checkDinheiroProprio, data.checkFamiliaresAmigos, data.checkFaturamentoProprio, data.checkNenhum];

  return booleans.map(bool => (bool ? "1" : "0")).join("");
}

// Baseada na encodeBooleansToBits
function encodeBooleansToBitsCompraProdutos(data) {
  const booleans = [data.checkComercioLocal, data.checkInternet, data.checkGrandesFornecedores, data.checkOutrosMeios];

  return booleans.map(bool => (bool ? "1" : "0")).join("");
}

// Baseada na encodeBooleansToBits
function encodeBooleansToBitsMarketing(data) {
  const booleans = [
    data.checkBoca,
    data.checkRadio,
    data.checkCartazPanfleto,
    data.checkJornal,
    data.checkWhatsApp,
    data.checkCarroSom,
    data.checkFacebook,
    data.checkOutros,
  ];

  return booleans.map(bool => (bool ? "1" : "0")).join("");
}

// Baseada na encodeBooleansToBits
function encodeBooleansToBitsNegocioCrescer(data) {
  const booleans = [
    data.checkDinheiro,
    data.checkCursoCapacitacao,
    data.checkMaqMoveisEquip,
    data.checkOrientacao,
    data.checkReforma,
    data.checkOutrosCrescer,
  ];

  return booleans.map(bool => (bool ? "1" : "0")).join("");
}

// Baseada na encodeBooleansToBits
function encodeBooleansToBitsMotivoNegocio(data) {
  const booleans = [
    data.checkSemEmprego,
    data.checkComplementarRendar,
    data.checkProprioPatrao,
    data.checkRealizarSonho,
    data.checkProjetoFamiliar,
    data.checkQueroTerExperienciaEmpreender,
  ];

  return booleans.map(bool => (bool ? "1" : "0")).join("");
}

//TODO: fazer as decodeBitsToBooleans para cada uma das encodes

//TODO: transformarDadosJaSouEmpreendedor
