import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import SearchIcon from "@mui/icons-material/Search";
import { Button, Card, FormControl, Grid, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";

import AtivarVagaTrabalhoModal from "../modals/AtivarVagaTrabalhoModal";
import ExcluirVagaTrabalhoModal from "../modals/ExcluirVagaTrabalhoModal";
import VisualizarVagaTrabalhoModal from "../modals/VisualizarVagaTrabalhoModal";
import DefaultTable from "../table/DefaultTable";

const termos = {
  dataCadastro: "Data de Cadastro",
  tipoVaga: "Tipo de Vaga",
  quantidadeVagas: "Vagas",
  interessados: "Interessados",
};

const tabelaColunas = [
  { field: "funcao", headerName: "Função" },
  { field: "situacao", headerName: "Situação" },
];

const keysHidden = ["dataCadastro", "tipoVaga", "quantidadeVagas", "interessados"];

const initialFilterState = {
  tipoVaga: "",
  funcao: "",
  situacao: "",
};

const ParceiroListarVagaTrabalho = ({ initialData }) => {
  const [visualizarVaga, setVisualizarVaga] = useState(false);
  const [ativarVaga, setAtivarVaga] = useState(false);
  const [excluirVaga, setExcluirVaga] = useState(false);
  const navigate = useNavigate();

  const [filter, setFilter] = useState(initialFilterState);
  const [filteredData, setFilteredData] = useState(initialData);

  const applyFilter = () => {
    const filtered = initialData.filter(vaga => {
      return vaga.tipoVaga.includes(filter.tipoVaga) || vaga.funcao.includes(filter.funcao) || vaga.situacao.includes(filter.situacao);
    });
    setFilteredData(filtered);
  };

  const resetFilter = () => {
    setFilter(initialFilterState);
    setFilteredData(initialData);
    applyFilter();
  };

  const tabelaHiddenLinhas = initialData.map(row =>
    keysHidden.reduce((acc, key) => {
      acc[key] = row[key];
      return acc;
    }, {}),
  );

  const getTabelaActions = () => [
    {
      title: "Visualizar",
      href: "",
      icon: "visibility",
      onClick: () => setVisualizarVaga(true),
    },
    {
      title: "Ativar",
      href: "",
      icon: "thumb_up",
      onClick: () => setAtivarVaga(true),
    },
    {
      title: "Currículos Selecionados",
      href: "",
      icon: "description",
    },
    {
      title: "Buscar Currículo",
      href: "",
      icon: "search",
    },
    {
      title: "Duplicar",
      href: "",
      icon: "file_copy",
      onClick: () => navigate("/vagas/duplicar"),
    },
    {
      title: "Interessados",
      href: "",
      icon: "person",
      onClick: () => navigate("/vagas/interessados"),
    },
    {
      title: "Editar",
      href: "",
      icon: "create",
      onClick: () => navigate("/vagas/editar"),
    },
    {
      title: "Excluir",
      icon: "delete",
      onClick: () => setExcluirVaga(true),
    },
  ];

  const fecharVisualizarVaga = () => {
    setVisualizarVaga(false);
  };

  const fecharAtivarVaga = () => {
    setAtivarVaga(false);
  };

  const fecharExcluirVaga = () => {
    setExcluirVaga(false);
  };

  return (
    <>
      <Stack spacing={1}>
        <Card
          color="#ffffff"
          sx={{
            borderRadius: "8px",
            padding: "16px",
            mt: "24px",
          }}
        >
          <Typography variant="h6">Filtro</Typography>
          <Grid
            container
            spacing={2}
            justifyContent="flex-end"
            mt={1}
          >
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
            >
              <FormControl fullWidth>
                <InputLabel id="select-label">Tipo de Vaga</InputLabel>
                <Select
                  id="tipoVaga"
                  label="Tipo de Vaga"
                  value={filter.tipoVaga}
                  onChange={e => setFilter({ ...filter, tipoVaga: e.target.value })}
                >
                  <MenuItem
                    value=""
                    disabled
                  >
                    Selecione
                  </MenuItem>
                  <MenuItem value="estagio">Estágio</MenuItem>
                  <MenuItem value="clt">CLT</MenuItem>
                  <MenuItem value="pj">PJ</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
            >
              <FormControl fullWidth>
                <InputLabel id="select-label">Função</InputLabel>
                <Select
                  id="funcao"
                  name="funcao"
                  label="Função"
                  value={filter.funcao}
                  onChange={e => setFilter({ ...filter, funcao: e.target.value })}
                >
                  <MenuItem
                    value=""
                    disabled
                  >
                    Selecione
                  </MenuItem>
                  <MenuItem value="Desenvolvedor Front-end">Desenvolvedor Front-end</MenuItem>
                  <MenuItem value="Desenvolvedor Back-end">Desenvolvedor Back-end</MenuItem>
                  <MenuItem value="Analista de Dados">Analista de Dados</MenuItem>
                  <MenuItem value="Gerente de Projetos">Gerente de Projetos</MenuItem>
                  <MenuItem value="Designer UX/UI">Designer UX/UI</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
            >
              <FormControl fullWidth>
                <InputLabel id="select-label">Situação</InputLabel>
                <Select
                  id="situacao"
                  name="situacao"
                  label="Situação"
                  value={filter.situacao}
                  onChange={e => setFilter({ ...filter, situacao: e.target.value })}
                >
                  <MenuItem
                    value=""
                    disabled
                  >
                    Selecione
                  </MenuItem>
                  <MenuItem value="Pendente">Pendente</MenuItem>
                  <MenuItem value="Aprovado">Aprovado</MenuItem>
                  <MenuItem value="Reprovado">Reprovado</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              md={2}
              display={"flex"}
              justifyContent={"flex-end"}
            >
              <Button
                variant="outlined"
                onClick={resetFilter}
                fullWidth
              >
                CANCELAR
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              md={2}
              display={"flex"}
              justifyContent={"flex-end"}
            >
              <Button
                variant="contained"
                onClick={applyFilter}
                sx={{ gap: "8px" }}
                style={{ fontFamily: "Rawline Medium" }}
                fullWidth
              >
                <SearchIcon />
                Pesquisar
              </Button>
            </Grid>
          </Grid>
        </Card>
        <Card
          color="#ffffff"
          sx={{
            borderRadius: "8px",
            padding: "16px",
            mt: "24px",
          }}
        >
          {/* Tabela */}
          <Box sx={{ flexGrow: 1 }}>
            <DefaultTable
              rows={filteredData}
              notFoundText={"Não foi localizado Cursos na situação pendente de aprovação!"}
              columns={tabelaColunas}
              hiddenRows={tabelaHiddenLinhas}
              actionButtons={getTabelaActions}
              termos={termos}
            ></DefaultTable>
          </Box>
        </Card>
      </Stack>
      {/* Modais */}
      <div>
        <VisualizarVagaTrabalhoModal
          showModal={visualizarVaga}
          handleClose={fecharVisualizarVaga}
        />
      </div>
      <div>
        <AtivarVagaTrabalhoModal
          showModal={ativarVaga}
          handleClose={fecharAtivarVaga}
        />
      </div>
      <div>
        <ExcluirVagaTrabalhoModal
          showModal={excluirVaga}
          handleClose={fecharExcluirVaga}
        />
      </div>
    </>
  );
};

ParceiroListarVagaTrabalho.propTypes = {
  initialData: PropTypes.array,
};

export default ParceiroListarVagaTrabalho;
