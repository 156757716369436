import React from "react";
import { Controller, useForm } from "react-hook-form";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import FormGroup from "@mui/material/FormGroup";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { useTheme } from "@mui/material/styles";
import dayjs from "dayjs";
import PropTypes from "prop-types";

import cidadaoService from "../../services/cidadao.service.js";
import { tp_escolaridade } from "../../services/constants.js";
import { payloadQueroSerEmpreendedor } from "../../utils/formulariosQueroSerEmpreendedor.js";
import InformationBox from "../boxes/InformationBox";
import CustomDatePicker from "./CustomDatePicker";
import { FormFieldsGrid } from "./DynamicForm.js";
import ReusableSwitch from "./fields/ReusableSwitch";
import FormAddress from "./FormAddress.js";
import FormMultipleInsertDouble from "./FormMultipleInsertDouble";
import { formatCPF, formatTelefone, validarCPF } from "./utils.js";

const valoresIniciais = {
  desejaAbrirNegocio: false,
  primeiraVezAbrirNegocio: false,
  checkDesempregado: false,
  checkSonho: false,
  checkQueroSerPatrao: false,
  checkQueroTerExperienciaEmpreender: false,
  checkQueroEmpreenderNovamente: false,
  checkProjetoFamiliar: false,
  checkEmpregado: false,
  setoresRadio: "",
  situacaoFuturaRadio: "",
  jaParticipouCurso: false,
  jaParticipouCursoDetalhes: [],
  gostariaParticiparCurso: false,
  gostariaReceberOrientacao: false,
  gostariaParticiparCursoMotivo: "",
  gostariaReceberOrientacaoMotivo: "",
  necessidadeFinanciamento: false,
  necessidadeFinanciamentoMotivo: "",
  conheceEmprestimos: false,
  nomeCompleto: "",
  dataNascimento: "",
  rg: "",
  cpf: "",
  generoRadio: "",
  escolaridade: "",
  enderecoCompleto: [
    {
      uf: "",
      municipio: "",
      cep: "",
      endereco: "",
      numero: "",
      complemento: "",
      referencia: "",
      bairro: "",
    },
  ],
  telefone: "",
  email: "",
};

const FormQueroSerEmpreendedor = ({ initialValues = valoresIniciais, visualizacao = false }) => {
  const theme = useTheme();

  const {
    control,
    handleSubmit,
    getValues,
    trigger,
    formState: { isSubmitting, errors },
  } = useForm({
    defaultValues: initialValues,
    mode: "all",
    reValidateMode: "onChange",
  });

  // useEffect(() => {
  //   // Percorre o dicionário definindo os valores do formulário
  //   Object.keys(initialValues).forEach(key => {
  //     setValue(key, initialValues[key]);
  //   });
  // }, [initialValues]);

  const fieldsNegocio = [
    {
      name: "desejaAbrirNegocio",
      label: "Você deseja abrir seu próprio negócio?",
      renderComponent: ({ value, onChange }) => (
        <>
          <Typography variant="subtitle2">Você deseja abrir seu próprio negócio?</Typography>
          <ReusableSwitch
            id={"desejaAbrirNegocio"}
            name={"desejaAbrirNegocio"}
            value={value ?? false}
            onChange={onChange}
            disabled={visualizacao}
          />
        </>
      ),
      size: { xs: 12 },
    },
    {
      name: "primeiraVezAbrirNegocio",
      label: "Você deseja abrir seu próprio negócio?",
      renderComponent: ({ value, onChange }) => (
        <>
          <Typography variant="subtitle2">É a primeira vez que você vai abrir o seu próprio negócio?</Typography>
          <ReusableSwitch
            id={"primeiraVezAbrirNegocio"}
            name={"primeiraVezAbrirNegocio"}
            value={value ?? false}
            onChange={onChange}
            disabled={visualizacao}
          />
        </>
      ),
      size: { xs: 12 },
    },
    {
      name: "motivosEmpreender",
      label: "Qual(is) o(s) motivo(s) que faz você desejar abrir seu próprio negócio?",
      validation: {
        validate: {
          peloMenosUmSelecionado: (_, formValues) => {
            const checkboxes = [
              formValues.checkDesempregado,
              formValues.checkSonho,
              formValues.checkQueroSerPatrao,
              formValues.checkQueroTerExperienciaEmpreender,
              formValues.checkQueroEmpreenderNovamente,
              formValues.checkProjetoFamiliar,
              formValues.checkEmpregado,
            ];
            return checkboxes.some(check => check) || "É necessário selecionar pelo menos uma opção";
          },
        },
      },
      renderComponent: ({ error }) => (
        <>
          <Typography variant="subtitle2">Qual(is) o(s) motivo(s) que faz você desejar abrir seu próprio negócio?</Typography>
          {error && <Typography sx={{ color: "#FF5630", fontSize: "12px" }}>{error.message}</Typography>}
          <FormControl error={!!error}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Controller
                    name="checkDesempregado"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={field.value}
                        style={{ color: error ? "#FF5630" : theme.palette.text.disabled }}
                        disabled={visualizacao}
                        onChange={e => {
                          field.onChange(e);
                          trigger("motivosEmpreender");
                        }}
                      />
                    )}
                  />
                }
                label="Estou desempregado, preciso de alguma atividade que me gere renda"
              />
              <FormControlLabel
                control={
                  <Controller
                    name="checkSonho"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={field.value}
                        style={{ color: error ? "#FF5630" : theme.palette.text.disabled }}
                        disabled={visualizacao}
                        onChange={e => {
                          field.onChange(e);
                          trigger("motivosEmpreender");
                        }}
                      />
                    )}
                  />
                }
                label="É um sonho que quero realizar"
              />
              <FormControlLabel
                control={
                  <Controller
                    name="checkQueroSerPatrao"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={field.value}
                        style={{ color: error ? "#FF5630" : theme.palette.text.disabled }}
                        disabled={visualizacao}
                        onChange={e => {
                          field.onChange(e);
                          trigger("motivosEmpreender");
                        }}
                      />
                    )}
                  />
                }
                label="Quero ser meu próprio patrão"
              />
              <FormControlLabel
                control={
                  <Controller
                    name="checkQueroTerExperienciaEmpreender"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={field.value}
                        style={{ color: error ? "#FF5630" : theme.palette.text.disabled }}
                        disabled={visualizacao}
                        onChange={e => {
                          field.onChange(e);
                          trigger("motivosEmpreender");
                        }}
                      />
                    )}
                  />
                }
                label="Quero ter a experiência de empreender um negócio"
              />
              <FormControlLabel
                control={
                  <Controller
                    name="checkQueroEmpreenderNovamente"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={field.value}
                        style={{ color: error ? "#FF5630" : theme.palette.text.disabled }}
                        disabled={visualizacao}
                        onChange={e => {
                          field.onChange(e);
                          trigger("motivosEmpreender");
                        }}
                      />
                    )}
                  />
                }
                label="Quero empreender novamente"
              />
              <FormControlLabel
                control={
                  <Controller
                    name="checkProjetoFamiliar"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={field.value}
                        style={{ color: error ? "#FF5630" : theme.palette.text.disabled }}
                        disabled={visualizacao}
                        onChange={e => {
                          field.onChange(e);
                          trigger("motivosEmpreender");
                        }}
                      />
                    )}
                  />
                }
                label="É um projeto familiar, precisam da minha participação"
              />
              <FormControlLabel
                control={
                  <Controller
                    name="checkEmpregado"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={field.value}
                        style={{ color: error ? "#FF5630" : theme.palette.text.disabled }}
                        disabled={visualizacao}
                        onChange={e => {
                          field.onChange(e);
                          trigger("motivosEmpreender");
                        }}
                      />
                    )}
                  />
                }
                label="Apesar de estar empregado, preciso de um complemento da minha renda"
              />
            </FormGroup>
          </FormControl>
        </>
      ),
      size: { xs: 12 },
    },
    {
      name: "setoresRadio",
      label: "O seu futuro negócio está mais próximo de qual dos setores abaixo?",
      validation: {
        required: "É necessário selecionar um setor",
      },
      renderComponent: ({ value, onChange, error }) => (
        <>
          <Typography variant="subtitle2">O seu futuro negócio está mais próximo de qual dos setores abaixo?</Typography>
          {error && <Typography sx={{ color: "#FF5630", fontSize: "12px" }}>{error.message}</Typography>}
          <FormGroup>
            <RadioGroup
              name="setoresRadio"
              onChange={onChange}
              value={value || ""}
              disabled={visualizacao}
            >
              <FormControlLabel
                value="industria"
                control={
                  <Radio
                    style={{ color: error ? "#FF5630" : theme.palette.primary.main }}
                    checked={value === "industria"}
                    disabled={visualizacao}
                    sx={{
                      "& .MuiFormControlLabel-root": {
                        color: error ? "#FF5630" : "inherit",
                      },
                    }}
                  />
                }
                label="Indústria (Você fabrica algo?)"
              />
              <FormControlLabel
                value="comercio"
                control={
                  <Radio
                    style={{ color: error ? "#FF5630" : theme.palette.primary.main }}
                    checked={value === "comercio"}
                    disabled={visualizacao}
                  />
                }
                label="Comércio (Você vende produtos?)"
              />
              <FormControlLabel
                value="servicos"
                control={
                  <Radio
                    style={{ color: error ? "#FF5630" : theme.palette.primary.main }}
                    checked={value === "servicos"}
                    disabled={visualizacao}
                  />
                }
                label="Serviços (Você é um prestador de serviços - hidráulica, pintura, alimentação, etc.)?"
              />
              <FormControlLabel
                value="outros"
                control={
                  <Radio
                    style={{ color: error ? "#FF5630" : theme.palette.primary.main }}
                    checked={value === "outros"}
                    disabled={visualizacao}
                  />
                }
                label="Outros"
              />
            </RadioGroup>
          </FormGroup>
        </>
      ),
      size: { sx: 12 },
    },
    {
      name: "situacaoFuturaRadio",
      label: "Qual a situação do seu negócio?",
      validation: {
        required: "É necessário selecionar uma situação",
      },
      renderComponent: ({ value, onChange, error }) => (
        <>
          <Typography variant="subtitle2">Qual a situação do seu negócio?</Typography>
          {error && <Typography sx={{ color: "#FF5630", fontSize: "12px" }}>{error.message}</Typography>}
          <FormGroup>
            <RadioGroup
              name="situacaoFuturaRadio"
              onChange={onChange}
              value={value || ""}
              disabled={visualizacao}
            >
              <FormControlLabel
                value="informal"
                control={
                  <Radio
                    style={{ color: error ? "#FF5630" : theme.palette.primary.main }}
                    checked={value === "informal"}
                    disabled={visualizacao}
                    sx={{
                      "& .MuiFormControlLabel-root": {
                        color: error ? "#FF5630" : "inherit",
                      },
                    }}
                  />
                }
                label="Informal"
              />
              <FormControlLabel
                value="formal"
                control={
                  <Radio
                    style={{ color: error ? "#FF5630" : theme.palette.primary.main }}
                    checked={value === "formal"}
                    disabled={visualizacao}
                  />
                }
                label="Formal - Microempresa"
              />
              <FormControlLabel
                value="formal-outros"
                control={
                  <Radio
                    style={{ color: error ? "#FF5630" : theme.palette.primary.main }}
                    checked={value === "formal-outros"}
                    disabled={visualizacao}
                  />
                }
                label="Formal - Outros portes"
              />
              <FormControlLabel
                value="formal-mei"
                control={
                  <Radio
                    style={{ color: error ? "#FF5630" : theme.palette.primary.main }}
                    checked={value === "formal-mei"}
                    disabled={visualizacao}
                  />
                }
                label="Formal - Microempreendedor Individual - MEI"
              />
            </RadioGroup>
          </FormGroup>
        </>
      ),
      size: { sx: 12 },
    },
    {
      name: "jaParticipouCurso",
      label: "Você já participou de algum curso sobre empreendedorismo, microcrédito, educação financeira?",
      renderComponent: ({ value, onChange }) => (
        <>
          <Typography variant="subtitle2">
            Você já participou de algum curso sobre empreendedorismo, microcrédito, educação financeira?
          </Typography>
          <ReusableSwitch
            id={"jaParticipouCurso"}
            name={"jaParticipouCurso"}
            value={value ?? false}
            onChange={e => {
              onChange(e);
              trigger("jaParticipouCursoDetalhes");
            }}
            disabled={visualizacao}
          />

          <Controller
            name="jaParticipouCursoDetalhes"
            control={control}
            rules={{
              validate: {
                required: (value, formValues) => {
                  if (formValues.jaParticipouCurso && (!value || value.length === 0)) {
                    return "É necessário informar pelo menos um curso quando já participou";
                  }
                  return true;
                },
              },
            }}
            render={({ field, fieldState: { error } }) =>
              value && (
                <div style={{ marginTop: 2 }}>
                  <FormMultipleInsertDouble
                    label1={"Qual(is) Curso(s)?"}
                    label2={"Qual a Instituição realizadora?"}
                    value1={"curso"}
                    value2={"instituicao"}
                    disabled={visualizacao}
                    values={field.value || []}
                    onFieldsChange={field.onChange}
                    error={error}
                  />
                </div>
              )
            }
          />
        </>
      ),
      size: { xs: 12 },
    },
    {
      name: "gostariaParticiparCurso",
      label:
        "Você gostaria de participar de algum curso sobre empreendedorismo, microcrédito, educação financeira, de outros assuntos para melhor gerir o seu negócio?",
      renderComponent: ({ value, onChange }) => (
        <>
          <Typography variant="subtitle2">
            Você gostaria de participar de algum curso sobre empreendedorismo, microcrédito, educação financeira, de outros assuntos para
            melhor gerir o seu negócio?
          </Typography>
          <ReusableSwitch
            id={"gostariaParticiparCurso"}
            name={"gostariaParticiparCurso"}
            value={value ?? false}
            onChange={e => {
              onChange(e);
              trigger("gostariaParticiparCursoMotivo");
            }}
            disabled={visualizacao}
          />

          <Controller
            name="gostariaParticiparCursoMotivo"
            control={control}
            rules={{
              validate: {
                required: (value, formValues) => {
                  if (formValues.gostariaParticiparCurso && !value) {
                    return "Este campo é obrigatório quando deseja participar do curso";
                  }
                  return true;
                },
              },
            }}
            render={({ field, fieldState: { error } }) =>
              value && (
                <TextField
                  {...field}
                  fullWidth
                  label="Por que motivo?"
                  error={!!error}
                  helperText={error?.message}
                  disabled={visualizacao}
                  placeholder="Insira o motivo de querer participar"
                  sx={{ mt: 2 }}
                />
              )
            }
          />
        </>
      ),
      size: { xs: 12 },
    },
    {
      name: "gostariaReceberOrientacao",
      label: "Você gostaria de receber orientação sobre como administrar seu negócio?",
      renderComponent: ({ value, onChange }) => (
        <>
          <Typography variant="subtitle2">Você gostaria de receber orientação sobre como administrar seu negócio?</Typography>
          <ReusableSwitch
            id={"gostariaReceberOrientacao"}
            name={"gostariaReceberOrientacao"}
            value={value ?? false}
            onChange={e => {
              onChange(e);
              trigger("gostariaReceberOrientacaoMotivo");
            }}
            disabled={visualizacao}
          />

          <Controller
            name="gostariaReceberOrientacaoMotivo"
            control={control}
            rules={{
              validate: {
                required: (value, formValues) => {
                  if (formValues.gostariaReceberOrientacao && !value) {
                    return "Este campo é obrigatório quando deseja receber orientação";
                  }
                  return true;
                },
              },
            }}
            render={({ field, fieldState: { error } }) =>
              value && (
                <TextField
                  {...field}
                  fullWidth
                  label="Por que motivo?"
                  error={!!error}
                  helperText={error?.message}
                  disabled={visualizacao}
                  placeholder="Insira o motivo de querer receber orientação"
                  sx={{ mt: 2 }}
                />
              )
            }
          />
        </>
      ),
      size: { xs: 12 },
    },
  ];

  const fieldsVoce = [
    {
      name: "necessidadeFinanciamento",
      label: "Você tem necessidade de um financiamento/empréstimo de microcrédito?",
      renderComponent: ({ value, onChange }) => (
        <>
          <Typography variant="subtitle2">Você tem necessidade de um financiamento/empréstimo de microcrédito?</Typography>
          <ReusableSwitch
            id={"necessidadeFinanciamento"}
            name={"necessidadeFinanciamento"}
            value={value ?? false}
            onChange={onChange}
            disabled={visualizacao}
          />
        </>
      ),
      size: { xs: 12 },
    },
    {
      name: "conheceEmprestimos",
      label:
        "Você conhece os empréstimos para grupos de microempreendedores que utilizam o chamado aval solidário como garantia de pagamento do empréstimo?",
      renderComponent: ({ value, onChange }) => (
        <>
          <Typography variant="subtitle2">
            Você conhece os empréstimos para grupos de microempreendedores que utilizam o chamado aval solidário como garantia de pagamento
            do empréstimo?
          </Typography>
          <ReusableSwitch
            id={"conheceEmprestimos"}
            name={"conheceEmprestimos"}
            value={value ?? false}
            onChange={onChange}
            disabled={visualizacao}
          />
        </>
      ),
      size: { xs: 12 },
    },
    {
      name: "nomeCompleto",
      label: "* Nome Completo",
      validation: {
        required: "Nome é obrigatório",
      },
      size: { xs: 12, sm: 6 },
    },
    {
      name: "dataNascimento",
      label: "Data de Nascimento",
      validation: {
        required: "Data de nascimento é obrigatória",
      },
      renderComponent: ({ value, onChange, error }) => (
        <>
          <CustomDatePicker
            label="* Data de Nascimento"
            id="dataNascimento"
            name="dataNascimento"
            value={value ? dayjs(value) : null}
            format="DD/MM/YYYY"
            onChange={onChange}
            disabled={visualizacao}
            error={!!error}
            helperText={error?.message}
          />
        </>
      ),
      size: { xs: 12, sm: 6 },
    },
    {
      name: "rg",
      label: "RG",
      validation: {
        required: "RG é obrigatório",
      },
      renderComponent: ({ value, onChange, error }) => (
        <FormGroup>
          <TextField
            label="* RG"
            id="rg"
            name="rg"
            value={value}
            placeholder="Insira o RG"
            type="number"
            onChange={onChange}
            inputProps={{ maxLength: 15 }}
            disabled={visualizacao}
            error={!!error}
            helperText={error?.message}
          />
        </FormGroup>
      ),
      size: { xs: 12, sm: 6 },
    },
    {
      name: "cpf",
      label: "CPF",
      validation: {
        required: "CPF é obrigatório",
        validate: {
          // required: "CPF  é obrigatório",
          tamanhoValido: value => {
            if (!value || value.trim() === "") {
              return true;
            }
            if (value.replace(/\D/g, "").length !== 11) {
              return "O CPF deve ter 11 dígitos";
            }
            return true;
          },
          cpfValido: value => {
            if (!value || value.trim() === "") {
              return true;
            }
            if (!validarCPF(value)) {
              return "O CPF informado não é válido";
            }
            return true;
          },
        },
      },
      renderComponent: ({ value, onChange, error }) => (
        <FormGroup>
          <TextField
            label="* CPF"
            id="cpf"
            name="cpf"
            value={formatCPF(value)}
            placeholder="Insira o CPF"
            type="text"
            onChange={onChange}
            inputProps={{ maxLength: 14 }}
            disabled={visualizacao}
            error={!!error}
            helperText={error?.message}
          />
        </FormGroup>
      ),
      size: { xs: 12, sm: 6 },
    },
    {
      name: "generoRadio",
      label: "Gênero",
      validation: {
        required: "Gênero é obrigatório",
      },
      renderComponent: ({ value, onChange, error }) => (
        <>
          <Typography variant="subtitle2">Gênero</Typography>
          {error && <Typography sx={{ color: "#FF5630", fontSize: "12px" }}>{error.message}</Typography>}
          <FormGroup>
            <RadioGroup
              name="generoRadio"
              onChange={onChange}
              value={value || ""}
              disabled={visualizacao}
            >
              <FormControlLabel
                value="FEM"
                control={
                  <Radio
                    style={{ color: error ? "#FF5630" : theme.palette.primary.main }}
                    checked={value === "FEM"}
                    disabled={visualizacao}
                    sx={{
                      "& .MuiFormControlLabel-root": {
                        color: error ? "#FF5630" : "inherit",
                      },
                    }}
                  />
                }
                label="Feminino"
              />
              <FormControlLabel
                value="MAS"
                control={
                  <Radio
                    style={{ color: error ? "#FF5630" : theme.palette.primary.main }}
                    checked={value === "MAS"}
                    disabled={visualizacao}
                  />
                }
                label="Masculino"
              />
              {/* Ainda não está sendo aceito pelo backend */}
              {/* <FormControlLabel
                value="NIN"
                control={
                  <Radio
                    style={{ color: error ? "#FF5630" : theme.palette.primary.main }}
                    checked={value === "NIN"}
                    disabled={visualizacao}
                  />
                }
                label="Prefiro não informar"
              /> */}
            </RadioGroup>
          </FormGroup>
        </>
      ),
      size: { sx: 12 },
    },
    {
      name: "escolaridade",
      label: "Escolaridade",
      validation: {
        required: "Escolaridade é obrigatória",
      },
      renderComponent: ({ value, onChange, error }) => {
        return (
          <FormControl
            fullWidth
            error={!!error}
          >
            <InputLabel sx={{ color: error ? "error" : "primary" }}>* Escolaridade</InputLabel>
            <Select
              id="escolaridade"
              name="escolaridade"
              value={value ?? ""}
              onChange={onChange}
              disabled={visualizacao}
            >
              <MenuItem
                key={""}
                value={""}
                disabled
              >
                Selecione uma escolaridade
              </MenuItem>

              {Object.keys(tp_escolaridade).map(escolaridade => (
                <MenuItem
                  key={escolaridade}
                  value={escolaridade}
                >
                  {tp_escolaridade[escolaridade]}
                </MenuItem>
              ))}
            </Select>
            {error && <FormHelperText>{error.message}</FormHelperText>}
          </FormControl>
        );
      },
      size: { xs: 12 },
    },
    {
      name: "enderecoCompleto",
      label: "Endereço",
      renderComponent: ({ value, onChange }) => (
        <FormAddress
          values={value}
          onChange={onChange}
        />
      ),
      size: { xs: 12, sm: 12 },
    },
    {
      name: "telefone",
      label: "* Telefone",
      validation: {
        required: "Telefone é obrigatório",
        validate: {
          tamanhoValido: value => {
            // Se o valor estiver vazio, retorna true (não valida tamanho)
            if (!value || value.trim() === "") {
              return true;
            }
            const tamanho = value.replace(/\D/g, "").length;
            return tamanho === 10 || tamanho === 11 || "O telefone deve ter 10 ou 11 dígitos";
          },
        },
      },
      renderComponent: ({ value, onChange, error }) => (
        <FormGroup>
          <TextField
            label="* Telefone"
            id="telefone"
            name="telefone"
            value={formatTelefone(value)}
            placeholder="Insira o Telefone"
            type="text"
            onChange={onChange}
            inputProps={{ maxLength: 15 }}
            disabled={visualizacao}
            error={!!error}
            helperText={error?.message}
          />
        </FormGroup>
      ),
      size: { xs: 12, sm: 6 },
    },
    {
      name: "email",
      label: "E-mail",
      validation: {
        required: "E-mail é obrigatório",
        pattern: {
          value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
          message: "Insira um e-mail válido",
        },
      },
      renderComponent: ({ value, onChange, error }) => (
        <FormGroup>
          <TextField
            label="* E-mail"
            id="email"
            name="email"
            value={value}
            placeholder="Insira o E-mail"
            type="text"
            onChange={onChange}
            disabled={visualizacao}
            error={!!error}
            helperText={error?.message}
          />
        </FormGroup>
      ),
      size: { xs: 12, sm: 6 },
    },
  ];

  const handleKeyDown = event => {
    // Impedir que o formularário seja enviado ao pressionar a tecla Enter
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  return (
    <form
      // TODO: MUDAR para receber o onSubmit da page (esse formulário será usado para o admin também)
      // para ficar dessa forma:
      // onSubmit={handleSubmit(onSubmit)}
      onSubmit={event => {
        event.preventDefault(); // Previne o comportamento padrão do formulário
        console.log("Valores reais do formulario:", getValues());

        // Validar todos os campos antes de submeter
        trigger().then(isValid => {
          if (isValid) {
            // Chama o handleSubmit do React Hook Form, que valida e envia os dados
            handleSubmit(data => {
              const payload = payloadQueroSerEmpreendedor(data);
              // console.log("Valores enviados para o backend:", payload);
              cidadaoService
                .postQueroEmpreender(payload)
                .then(() => {
                  window.location.reload();
                })
                .catch(error => {
                  console.log("Erro no envio dos dados do Questionário: ", error);
                });
            })();
          }
        });

        // Se você quer ver os erros de validação
        console.log("Erros de validação:", errors);
      }}
      onKeyDown={handleKeyDown}
    >
      <div>
        <Accordion style={{ border: "1px solid grey" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{ fontFamily: "Rawline Bold", color: "primary.main" }}
          >
            <InfoIcon sx={{ marginRight: "8px", color: "primary.main" }} />
            Sobre o negócio
          </AccordionSummary>
          <AccordionDetails>
            <FormFieldsGrid
              fields={fieldsNegocio}
              control={control}
              errors={errors}
            />
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ border: "1px solid grey" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{ fontFamily: "Rawline Bold", color: "primary.main" }}
          >
            <InfoIcon sx={{ marginRight: "8px", color: "primary.main" }} />
            Sobre você
          </AccordionSummary>
          <AccordionDetails>
            <FormFieldsGrid
              fields={fieldsVoce}
              control={control}
              errors={errors}
            />
          </AccordionDetails>
        </Accordion>
      </div>

      <InformationBox
        title="Atenção:"
        message="Confira todas as respostas antes de clicar no botão 'Salvar Respostas'. Após salvar, não será permitido alterar as respostas."
      />

      <Grid
        container
        spacing={1}
        justifyContent="flex-end"
        mt={2}
      >
        <Grid item>
          <Button
            variant="outlined"
            onClick={() => console.log("cancelar")}
            sx={{ gap: "8px" }}
            style={{ fontFamily: "Rawline Medium", textTransform: "uppercase" }}
          >
            Cancelar
          </Button>
        </Grid>
        <Grid item>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
            // onClick={() => onSubmit(getValues())}
            fullWidth
            sx={{ gap: "8px" }}
            style={{ fontFamily: "Rawline Medium", textTransform: "uppercase" }}
          >
            <SaveIcon fontSize="small" />
            <Typography variant={"BUTTON TEXT"}>Salvar resposta</Typography>
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

FormQueroSerEmpreendedor.propTypes = {
  initialValues: PropTypes.object,
  visualizacao: PropTypes.bool,
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default FormQueroSerEmpreendedor;
