import React, { useEffect, useState } from "react";

import { Card, Container, FormGroup, Grid, Stack, TextField, Typography } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import { tabelaCursosPendentes } from "../../components/formularios/dadosMockados";
import { formatCNPJ } from "../../components/formularios/utils.js";
import TitlePage from "../../components/title-page/TitlePage";

const CursosVisualizar = () => {
  const valoresIniciais = {
    nomeDoCurso: "<Nome do Curso>",
    cnpj: "<CNPJ>",
    ofertante: "<Ofertante>",
    modalidade: "<Modalidade>",
    tipo: "<Tipo>",
    pronatec: "<Pronatec>",
    inicioPreMatricula: "00/00/0000",
    fimPreMatricula: "00/00/0000",
    turno: "<Turno>",
    vagas: 200,
    cargaHoraria: "<Carga Horária>",
    idadeMinima: "<Idade Mínima>",
    idadeMaxima: "<Idade Máxima>",
    escolaridadeMinima: "<Escolaridade Mínima>",
    abrangencia: "<Abrangência>",
    uf: "<UF>",
    municipio: "<Município>",
    inicioCurso: "<Início do Curso>",
    fimCurso: "<Fim do Curso>",
    requisito: "<Requisito>",
    linkExterno: "<Link externo do Curso>",
    ementa: "<Ementa>",
    descricao: "<Breve descrição>",
  };

  const [valores, setValores] = useState(valoresIniciais);
  const id = JSON.parse(localStorage.getItem("cursosID"));

  useEffect(() => {
    if (id) {
      const dados = tabelaCursosPendentes.find(item => item.id == id);

      if (dados) {
        setValores(dados);
      }
    }
  });

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[
          { href: "/parceiros/listar/pendentes", text: "Listar Parceiros Pendentes" },
          { href: "/parceiros/visualizar/" + id, text: "Informações do Parceiro" },
        ]}
        currentPage="Visualizar Curso"
      />

      <TitlePage title={"Visualizar Curso"} />

      <Card
        color="#ffffff"
        sx={{
          borderRadius: "8px",
          padding: "16px",
          mt: "24px",
        }}
      >
        <Stack spacing={1}>
          <Grid
            container
            spacing={1}
          >
            <Grid
              item
              xs={12}
            >
              <Typography variant="h6">{valores.nomeDoCurso}</Typography>
            </Grid>
            <Grid
              item
              xs={6}
            >
              <FormGroup>
                <TextField
                  disabled
                  id="cnpj"
                  name="cnpj"
                  label="CNPJ"
                  placeholder="CNPJ"
                  value={formatCNPJ(valores.cnpj) || ""}
                  type="text"
                />
              </FormGroup>
            </Grid>
            <Grid
              item
              xs={6}
            >
              <FormGroup>
                <TextField
                  disabled
                  id="ofertante"
                  name="ofertante"
                  label="Ofertante"
                  placeholder="Ofertante"
                  value={valores.ofertante}
                  type="text"
                />
              </FormGroup>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={1}
          >
            <Grid
              item
              xs={3}
            >
              <FormGroup>
                <TextField
                  disabled
                  id="modalidade"
                  name="modalidade"
                  label="Modalidade"
                  placeholder="Modalidade"
                  value={valores.modalidade}
                  type="text"
                />
              </FormGroup>
            </Grid>
            <Grid
              item
              xs={3}
            >
              <FormGroup>
                <TextField
                  disabled
                  id="tipo"
                  name="tipo"
                  label="Tipo"
                  placeholder="Tipo"
                  value={valores.tipo}
                  type="text"
                />
              </FormGroup>
            </Grid>
            <Grid
              item
              xs={1.5}
            >
              <FormGroup>
                <TextField
                  disabled
                  id="pronatec"
                  name="pronatec"
                  label="Pronatec"
                  placeholder="Pronatec"
                  value={valores.pronatec}
                  type="text"
                />
              </FormGroup>
            </Grid>
            <Grid
              item
              xs={2.25}
            >
              <FormGroup>
                <TextField
                  disabled
                  id="inicioPreMatricula"
                  name="inicioPreMatricula"
                  label="Início pré-matrícula"
                  placeholder="Início pré-matrícula"
                  value={valores.inicioPreMatricula}
                  type="text"
                />
              </FormGroup>
            </Grid>
            <Grid
              item
              xs={2.25}
            >
              <FormGroup>
                <TextField
                  disabled
                  id="fimPreMatricula"
                  name="fimPreMatricula"
                  label="Fim pré-matrícula"
                  placeholder="Fim pré-matrícula"
                  value={valores.fimPreMatricula}
                  type="text"
                />
              </FormGroup>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={1}
          >
            <Grid
              item
              xs={2.4}
            >
              <FormGroup>
                <TextField
                  disabled
                  id="turno"
                  name="turno"
                  label="Turno"
                  placeholder="Turno"
                  value={valores.turno}
                  type="text"
                />
              </FormGroup>
            </Grid>
            <Grid
              item
              xs={2.4}
            >
              <FormGroup>
                <TextField
                  disabled
                  id="vagas"
                  name="vagas"
                  label="Vagas"
                  placeholder="Vagas"
                  value={valores.vagas}
                  type="text"
                />
              </FormGroup>
            </Grid>
            <Grid
              item
              xs={2.4}
            >
              <FormGroup>
                <TextField
                  disabled
                  id="cargaHoraria"
                  name="cargaHoraria"
                  label="Carga Horária"
                  placeholder="Carga Horária"
                  value={valores.cargaHoraria}
                  type="text"
                />
              </FormGroup>
            </Grid>
            <Grid
              item
              xs={2.4}
            >
              <FormGroup>
                <TextField
                  disabled
                  id="idadeMinima"
                  name="idadeMinima"
                  label="Idade Mínima"
                  placeholder="Idade Mínima"
                  value={valores.idadeMinima}
                  type="text"
                />
              </FormGroup>
            </Grid>
            <Grid
              item
              xs={2.4}
            >
              <FormGroup>
                <TextField
                  disabled
                  id="idadeMaxima"
                  name="idadeMaxima"
                  label="Idade Máxima"
                  placeholder="Idade Máxima"
                  value={valores.idadeMaxima}
                  type="text"
                />
              </FormGroup>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={1}
          >
            <Grid
              item
              xs={12}
            >
              <FormGroup>
                <TextField
                  disabled
                  id="escolaridadeMinima"
                  name="escolaridadeMinima"
                  label="Escolaridade Mínima"
                  placeholder="Escolaridade Mínima"
                  value={valores.escolaridadeMinima}
                  type="text"
                />
              </FormGroup>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={1}
          >
            <Grid
              item
              xs={2.4}
            >
              <FormGroup>
                <TextField
                  disabled
                  id="abrangencia"
                  name="abrangencia"
                  label="Abrangência"
                  placeholder="Abrangência"
                  value={valores.abrangencia}
                  type="text"
                />
              </FormGroup>
            </Grid>
            <Grid
              item
              xs={2.4}
            >
              <FormGroup>
                <TextField
                  disabled
                  id="uf"
                  name="uf"
                  label="UF"
                  placeholder="UF"
                  value={valores.uf}
                  type="text"
                />
              </FormGroup>
            </Grid>
            <Grid
              item
              xs={2.4}
            >
              <FormGroup>
                <TextField
                  disabled
                  id="municipio"
                  name="municipio"
                  label="Município"
                  placeholder="Município"
                  value={valores.municipio}
                  type="text"
                />
              </FormGroup>
            </Grid>
            <Grid
              item
              xs={2.4}
            >
              <FormGroup>
                <TextField
                  disabled
                  id="inicioCurso"
                  name="inicioCurso"
                  label="Início do Curso"
                  placeholder="Início do Curso"
                  value={valores.inicioCurso}
                  type="text"
                />
              </FormGroup>
            </Grid>
            <Grid
              item
              xs={2.4}
            >
              <FormGroup>
                <TextField
                  disabled
                  id="fimCurso"
                  name="fimCurso"
                  label="Fim do Curso"
                  placeholder="Fim do Curso"
                  value={valores.fimCurso}
                  type="text"
                />
              </FormGroup>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={1}
          >
            <Grid
              item
              xs={6}
            >
              <FormGroup>
                <TextField
                  disabled
                  id="requisito"
                  name="requisito"
                  label="Requisito"
                  placeholder="Requisito"
                  value={valores.requisito}
                  type="text"
                />
              </FormGroup>
            </Grid>
            <Grid
              item
              xs={6}
            >
              <FormGroup>
                <TextField
                  disabled
                  id="linkExterno"
                  name="linkExterno"
                  label="Link Externo do Curso"
                  placeholder="Link externo do Curso"
                  value={valores.linkExterno}
                  type="text"
                />
              </FormGroup>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={1}
          >
            <Grid
              item
              xs={12}
            >
              <FormGroup>
                <TextField
                  disabled
                  multiline
                  rows={3}
                  id="ementa"
                  name="ementa"
                  label="Ementa"
                  placeholder="Ementa"
                  value={valores.ementa}
                  type="text"
                />
              </FormGroup>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={1}
          >
            <Grid
              item
              xs={12}
            >
              <FormGroup>
                <TextField
                  disabled
                  multiline
                  rows={3}
                  id="descricao"
                  name="descricao"
                  label="Breve descrição"
                  placeholder="Breve descrição"
                  value={valores.descricao}
                  type="text"
                />
              </FormGroup>
            </Grid>
          </Grid>
        </Stack>
      </Card>
    </Container>
  );
};

export default CursosVisualizar;
