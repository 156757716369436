import React from "react";
import { Route, Routes } from "react-router-dom";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import PageAlertaInteresseMPO from "../../pages/admin/PageAlertaInteresseMPO";
import PageAlertaPedidoMPO from "../../pages/admin/PageAlertaPedidoMPO";
import PageAnalisarCursoPendente from "../../pages/admin/PageAnalisarCursoPendente";
import PageAnalisarInformacoesComplementares from "../../pages/admin/PageAnalisarInformacoesComplementares";
import PageAnalisarParceiroPendente from "../../pages/admin/PageAnalisarParceiroPendente";
import PageConsultarJornadaCidadao from "../../pages/admin/PageConsultarJornadaCidadao";
import PageConsultarMPO from "../../pages/admin/PageConsultarMPO";
import PageConsultarPainelIndicadores from "../../pages/admin/PageConsultarPainelIndicadores";
import PageCriteriosVaga from "../../pages/admin/PageCriteriosVaga";
import PageCursosPessoasInteressadas from "../../pages/admin/PageCursosPessoasInteressadas";
import PageCursosVisualizar from "../../pages/admin/PageCursosVisualizar";
import PageEditarParceiro from "../../pages/admin/PageEditarParceiro";
import PageEditarPerfil from "../../pages/admin/PageEditarPerfil";
import PageEditarUsuario from "../../pages/admin/PageEditarUsuario";
import PageEmails from "../../pages/admin/PageEmails";
import PageExcluirPerfil from "../../pages/admin/PageExcluirPerfil";
import PageExcluirUsuario from "../../pages/admin/PageExcluirUsuario";
import PageGerarRelatorioIndicadores from "../../pages/admin/PageGerarRelatorioIndicadores";
import PageHistoricoAlteracoesCurso from "../../pages/admin/PageHistoricoAlteracoesCurso";
import PageHistoricoCursos from "../../pages/admin/PageHistoricoCursos";
import PageHistoricoSituacaoPerfil from "../../pages/admin/PageHistoricoSituacaoPerfil";
import PageHistoricoSituacaoUsuario from "../../pages/admin/PageHistoricoSituacaoUsuario";
import PageHomeAdmin from "../../pages/admin/PageHomeAdmin";
import PageInativarParceiro from "../../pages/admin/PageInativarParceiro";
import PageIncluirCursosTrilha from "../../pages/admin/PageIncluirCursosTrilha";
import PageInformacoesComplementares from "../../pages/admin/PageInformacoesComplementares";
import PageJaSouEmpreendedor from "../../pages/admin/PageJaSouEmpreendedor";
import PageListarAnexosCurso from "../../pages/admin/PageListarAnexosCurso";
import PageListarCursosAprovados from "../../pages/admin/PageListarCursosAprovados";
import PageListarCursosPendentes from "../../pages/admin/PageListarCursosPendentes";
import PageListarCursosReprovados from "../../pages/admin/PageListarCursosReprovados";
import PageListarCursosSiteInativo from "../../pages/admin/PageListarCursosSiteInativo";
import PageListarInteressadosMPO from "../../pages/admin/PageListarInteressadosMPO";
import PageListarInteressadosMPOParceiro from "../../pages/admin/PageListarInteressadosMPOParceiro";
import PageParceirosAprovados from "../../pages/admin/PageListarParceirosAprovados";
import PageParceirosPendentes from "../../pages/admin/PageListarParceirosPendentes";
import PageParceirosReprovados from "../../pages/admin/PageListarParceirosReprovados";
import PageListarPerfis from "../../pages/admin/PageListarPerfis";
import PageListarUsuarios from "../../pages/admin/PageListarUsuarios";
import PageListarVagasTrabalho from "../../pages/admin/PageListarVagasTrabalho";
import PageNovoPerfil from "../../pages/admin/PageNovoPerfil";
import PageNovoUsuario from "../../pages/admin/PageNovoUsuario";
import PageQueroSerEmpreendedor from "../../pages/admin/PageQueroSerEmpreendedor";
import PageSituacaoInteressado from "../../pages/admin/PageSituacaoInteressado";
import PageEditarTrilha from "../../pages/admin/PageTrilhaEditar";
import PageTrilhaListar from "../../pages/admin/PageTrilhaListar";
import PageNovaTrilha from "../../pages/admin/PageTrilhaNova";
import PageVisualizarTrilha from "../../pages/admin/PageTrilhaVisualizar";
import PageUsuarioRepresentanteParceiro from "../../pages/admin/PageUsuariosRepresentantesParceiro";
import PageVagasCurriculo from "../../pages/admin/PageVagasCurriculo";
import PageVagasPessoasInteressadas from "../../pages/admin/PageVagasPessoasInteressadas";
import PageVisualizarAlteracoes from "../../pages/admin/PageVisualizarAlteracoes";
import PageVisualizarAlteracoesCursoPendente from "../../pages/admin/PageVisualizarAlteracoesCursoPendente";
import PageVisualizarAlteracoesDocumentos from "../../pages/admin/PageVisualizarAlteracoesDocumentos";
import PageVisualizarInformacoesComplementares from "../../pages/admin/PageVisualizarInformacoesComplementares";
import PageVisualizarParceiroPendente from "../../pages/admin/PageVisualizarParceiroPendente";
import PageVisualizarPerfil from "../../pages/admin/PageVisualizarPerfil";
import PageVisualizarUsuario from "../../pages/admin/PageVisualizarUsuario";
import PageVisualizarVagaTrabalho from "../../pages/admin/PageVisualizarVagaTrabalho";
import FAQ from "../../pages/FAQ";
import NotFound from "../../pages/NotFound";
import PageComponents from "../../pages/PageComponents";
import PageComponentsDefaultTable from "../../pages/PageComponentsDefaultTable";
import PageComponentsForm from "../../pages/PageComponentsForm";
import Profile from "../../pages/Profile";

const queryClient = new QueryClient();

const AdminRoutes = () => (
  <QueryClientProvider client={queryClient}>
    <Routes>
      <Route
        path="/"
        element={<PageHomeAdmin />}
      />
      <Route
        path="/home"
        element={<PageHomeAdmin />}
      />
      <Route
        path="/profile"
        element={<Profile />}
      />
      <Route
        path="/components"
        element={<PageComponents />}
      />
      <Route
        path="/components-default-table"
        element={<PageComponentsDefaultTable />}
      />
      <Route
        path="/components-form"
        element={<PageComponentsForm />}
      />

      {/* Parceiros */}
      <Route
        path="/parceiros/aprovados"
        element={<PageParceirosAprovados />}
      />
      <Route
        path="/parceiros/representantes"
        element={<PageUsuarioRepresentanteParceiro />}
      />
      <Route
        path="/parceiros/reprovados"
        element={<PageParceirosReprovados />}
      />
      <Route
        path="/parceiros/reprovados/inativar/:id?"
        element={<PageInativarParceiro />}
      />
      <Route
        path="/parceiros/pendentes"
        element={<PageParceirosPendentes />}
      />
      <Route
        path="/parceiros/editar/:id?"
        element={<PageEditarParceiro />}
      />
      <Route
        path="/parceiros/visualizar/:id?"
        element={<PageVisualizarParceiroPendente />}
      />
      <Route
        path="/parceiros/analisar/:id?"
        element={<PageAnalisarParceiroPendente />}
      />
      <Route
        path="/parceiros/alteracoes/visualizar/:id?"
        element={<PageVisualizarAlteracoes />}
      />
      <Route
        path="/parceiros/alteracoes-documentos/visualizar/:id?"
        element={<PageVisualizarAlteracoesDocumentos />}
      />

      {/* Empresa Complementar */}
      <Route
        path="/parceiros/empresa-complementar/cadastrar/:id?"
        element={<PageInformacoesComplementares />}
      />
      <Route
        path="/parceiros/empresa-complementar/analisar/:id?"
        element={<PageAnalisarInformacoesComplementares />}
      />
      <Route
        path="/parceiros/empresa-complementar/visualizar/:id?"
        element={<PageVisualizarInformacoesComplementares />}
      />

      {/* Vagas de Trabalho */}
      <Route
        path="/vagas/interessadas/:id?"
        element={<PageVagasPessoasInteressadas />}
      />
      <Route
        path="/vagas/interessadas/curriculo/:id?"
        element={<PageVagasCurriculo />}
      />
      <Route
        path="/vagas/listar"
        element={<PageListarVagasTrabalho />}
      />
      <Route
        path="/vagas/visualizar/:id?"
        element={<PageVisualizarVagaTrabalho />}
      />
      <Route
        path="/vagas/visualizar/criterios"
        element={<PageCriteriosVaga />}
      />

      {/* Cursos */}
      <Route
        path="/cursos/pendentes"
        element={<PageListarCursosPendentes />}
      />
      <Route
        path="/cursos/pendentes/historico/:id?"
        element={<PageHistoricoCursos />}
      />
      <Route
        path="/cursos/pendentes/analisar/:id?"
        element={<PageAnalisarCursoPendente />}
      />
      <Route
        path="/cursos/pendentes/analisar/historico/:id?"
        element={<PageHistoricoAlteracoesCurso />}
      />
      <Route
        path="/cursos/pendentes/analisar/alteracoes/:id?"
        element={<PageVisualizarAlteracoesCursoPendente />}
      />
      <Route
        path="/cursos/aprovados"
        element={<PageListarCursosAprovados />}
      />
      <Route
        path="/cursos/reprovados"
        element={<PageListarCursosReprovados />}
      />
      <Route
        path="/cursos/site-inativo"
        element={<PageListarCursosSiteInativo />}
      />
      <Route
        path="/cursos/visualizar/:id?"
        element={<PageCursosVisualizar />}
      />
      <Route
        path="/cursos/interessadas/:id?"
        element={<PageCursosPessoasInteressadas />}
      />
      <Route
        path="/cursos/anexos"
        element={<PageListarAnexosCurso />}
      />

      {/* Trilhas */}
      <Route
        path="/trilhas/novo"
        element={<PageNovaTrilha />}
      />
      <Route
        path="/trilhas/"
        element={<PageTrilhaListar />}
      />
      <Route
        path="/trilhas/editar/:id?"
        element={<PageEditarTrilha />}
      />
      <Route
        path="/trilhas/visualizar/:id?"
        element={<PageVisualizarTrilha />}
      />
      <Route
        path="/trilhas/incluir/:id?"
        element={<PageIncluirCursosTrilha />}
      />

      {/* Indicadores */}
      <Route
        path="/consultar-painel-indicadores"
        element={<PageConsultarPainelIndicadores />}
      />
      <Route
        path="/gerar-relatorio-indicadores"
        element={<PageGerarRelatorioIndicadores />}
      />

      {/* Usuários */}
      <Route
        path="/usuarios"
        element={<PageListarUsuarios />}
      />
      <Route
        path="/usuarios/novo"
        element={<PageNovoUsuario />}
      />
      <Route
        path="/usuarios/editar/:id?"
        element={<PageEditarUsuario />}
      />
      <Route
        path="/usuarios/excluir/:id?"
        element={<PageExcluirUsuario />}
      />
      <Route
        path="/usuarios/visualizar/:id?"
        element={<PageVisualizarUsuario />}
      />
      <Route
        path="/usuarios/visualizar/historico"
        element={<PageHistoricoSituacaoUsuario />}
      />

      {/* Perfis */}
      <Route
        path="/perfis"
        element={<PageListarPerfis />}
      />
      <Route
        path="/perfis/novo"
        element={<PageNovoPerfil />}
      />
      <Route
        path="/perfis/editar/:id?"
        element={<PageEditarPerfil />}
      />
      <Route
        path="/perfis/visualizar/:id?"
        element={<PageVisualizarPerfil />}
      />
      <Route
        path="/perfis/visualizar/historico"
        element={<PageHistoricoSituacaoPerfil />}
      />
      <Route
        path="/perfis/excluir"
        element={<PageExcluirPerfil />}
      />

      {/* MPO (Microcrédito) */}
      <Route
        path="/microcredito/listar"
        element={<PageListarInteressadosMPO />}
      />
      <Route
        path="/microcredito/interessados"
        element={<PageAlertaInteresseMPO />}
      />
      <Route
        path="/microcredito/pedidos-microcredito"
        element={<PageAlertaPedidoMPO />}
      />
      <Route
        path="/microcredito/consultar-jornada-cidadao"
        element={<PageConsultarJornadaCidadao />}
      />
      <Route
        path="/microcredito/interessados-parceiro"
        element={<PageListarInteressadosMPOParceiro />}
      />
      <Route
        path="/microcredito/situacao"
        element={<PageSituacaoInteressado />}
      />
      <Route
        path="/microcredito/consultar"
        element={<PageConsultarMPO />}
      />
      <Route
        path="/microcredito/quero-ser-empreendedor"
        element={<PageQueroSerEmpreendedor />}
      />
      <Route
        path="/microcredito/ja-sou-empreendedor"
        element={<PageJaSouEmpreendedor />}
      />

      {/* Emails */}
      <Route
        path="/emails"
        element={<PageEmails />}
      />

      {/* Gerais */}
      <Route
        path="/faq"
        element={<FAQ />}
      />
      <Route
        path="*"
        element={<NotFound />}
      />

      {/* Auth routes should be home */}
      <Route
        path="/parceiro_login"
        element={<PageHomeAdmin />}
      />
      <Route
        path="/admin_login"
        element={<PageHomeAdmin />}
      />
      <Route
        path="/cidadao_login"
        element={<PageHomeAdmin />}
      />
      <Route
        path="/login"
        element={<PageHomeAdmin />}
      />
      <Route
        path="/register"
        element={<PageHomeAdmin />}
      />
    </Routes>
  </QueryClientProvider>
);

export default AdminRoutes;
